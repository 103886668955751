//import { browserHistory } from 'react-router';
import auth0 from 'auth0-js';
import decode from 'jwt-decode';
import STAGES from './stages.js';
const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';

//const REDIRECT = 'http://yotoplay.local:3000/callback';
const REDIRECT = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
}/callback`;
const SCOPE = 'YOUR_SCOPE';

/* eslint-disable i18next/no-literal-string */
// noinspection SpellCheckingInspection
const AUTH0_CLIENT_ID = {
    dev: 'l7pheeQJH1rYR4EKJqK5D0WKqvhR19G1',
    test: 'l7pheeQJH1rYR4EKJqK5D0WKqvhR19G1',
    beta: '90v6OFRB0bKTtpSCqT7edO7rlu9je8nb',
    prod: '90v6OFRB0bKTtpSCqT7edO7rlu9je8nb'
};

const AUTH0_CLIENT_DOMAIN = {
    prod: 'login.yotoplay.com',
    beta: 'login.yotoplay.com',
    test: 'footest.eu.auth0.com',
    dev: 'footest.eu.auth0.com'
};

/* eslint-enable i18next/no-literal-string */

const AUDIENCE =
    STAGES.API_DOMAINS[STAGES.CLIENT_URLS[window.location.hostname]];
const CLIENT_ID = AUTH0_CLIENT_ID[STAGES.CLIENT_URLS[window.location.hostname]];
const CLIENT_DOMAIN =
    AUTH0_CLIENT_DOMAIN[STAGES.CLIENT_URLS[window.location.hostname]];

const PARAM_ACCESS_TOKEN = 'access_token';
const PARAM_ID_TOKEN = 'id_token';

const auth = new auth0.WebAuth({
    clientID: CLIENT_ID,
    domain: CLIENT_DOMAIN
});

export function login(returnUrl) {
    const TOKEN_ID_TOKEN = 'token id_token';
    auth.authorize({
        responseType: TOKEN_ID_TOKEN,
        redirectUri: REDIRECT + (returnUrl ? `?returnUrl=${returnUrl}` : ''),
        audience: AUDIENCE,
        scope: SCOPE
    });
}

export function logout() {
    console.log('LOGOUT');
    clearIdToken();
    clearAccessToken();
    auth.logout({
        returnTo: `${window.location.protocol}//${window.location.host}`
    });
}

export function allowSso() {
    let accessToken = getParameterByName(PARAM_ACCESS_TOKEN);
    if (accessToken) {
        if (accessToken === 'sign-out') {
            clearAccessToken();
        } else {
            setAccessToken();
        }
    }
    const locationWithoutSsoDetails =
        window.location.href.match(/^[^#]+[#]?[^&]*/)[0];
    if (locationWithoutSsoDetails !== window.location.href) {
        window.location.href = locationWithoutSsoDetails;
    }
    //replace(window.location.href.match(/^[^\#]+[\#]?[^&]*/)[0])
    //console.log("onEnter")
    //console.log(window.location)
}

export function requireAuth() {
    let accessToken = getParameterByName(PARAM_ACCESS_TOKEN);
    if (accessToken) {
        if (accessToken === 'sign-out') {
            clearAccessToken();
        } else {
            setAccessToken();
        }
    }
    if (!isLoggedIn()) {
        //login();
        //replace({pathname: '/'});
    }
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearIdToken() {
    localStorage.removeItem(ID_TOKEN_KEY);
}

function clearAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
}

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
    //console.log(`matching ${name}`);
    //console.log(`of ${window.location.hash}`);
    //    let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
    let match = RegExp(`[#&]${name}=([^&#?]*)`).exec(window.location.hash);
    //console.log(`[#&]${name}=([^&#\\?]*)`);
    //console.log(match);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

// Get and store access_token in local storage
export function setAccessToken() {
    let accessToken = getParameterByName(PARAM_ACCESS_TOKEN);
    if (accessToken) {
        //console.log("QS accessToken: " + accessToken.slice(0, 10))
    } else {
        //console.log("QS accessToken empty")
    }
    //console.log(accessToken)
    if (accessToken) localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

// Get and store id_token in local storage
export function setIdToken() {
    let idToken = getParameterByName(PARAM_ID_TOKEN);
    localStorage.setItem(ID_TOKEN_KEY, idToken);
}

export function isAdmin() {
    const token = tryDecodeToken(localStorage.getItem(ACCESS_TOKEN_KEY));
    if (!token) return null;

    if (!token['https://yotoplay.com/roles']) {
        return null;
    }
    return token['https://yotoplay.com/roles'].split(',').includes('admin');
}

export function mediaAccountId() {
    const token = tryDecodeToken(localStorage.getItem(ACCESS_TOKEN_KEY));
    if (!token) return null;

    if (!token['https://yotoplay.com/mediaAccountId']) {
        return null;
    }
    return token['https://yotoplay.com/mediaAccountId'];
}

export function isLoggedIn() {
    const token = getAccessToken();
    return !!token && !isTokenExpired(token);
}

function getTokenExpirationDate(encodedToken) {
    const token = tryDecodeToken(encodedToken);
    if (!token || !token.exp) {
        return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
}

function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}

function tryDecodeToken(encodedToken) {
    let decodedToken = {};
    try {
        decodedToken = decode(encodedToken);
    } catch (err) {
        return null;
    }
    return decodedToken;
}

import React, { Component } from "react";
import { login, isLoggedIn } from "../utils/AuthService";
import { browserHistory } from "react-router";
import PageHeader from "./PageHeaderMyoMenu";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const MY_CARDS_URL = "/my-cards";

const propTypes = {
  i18n: PropTypes.object,
};

class Create extends Component {
  componentDidMount() {
    if (isLoggedIn()) {
      browserHistory.push(MY_CARDS_URL);
    }
  }

  render() {
    return (
      <>
        <div
          className="create-page"
          style={{ textAlign: "center", paddingBottom: "0" }}
        >
          <PageHeader
            title={this.props.i18n.t("myo_landing_title", "Make Your Own Card")}
            i18n={this.props.i18n}
          />

          <div className="container" style={{ width: "100%", padding: "0" }}>
            <div className="makeyourown_banner" />

            <h4 style={{ padding: "20px", lineHeight: "26px" }}>
              <Trans i18nKey="myo_landing_description">
                Fill blank Yoto Cards with your own audio and listen to them on
                your player or the Yoto App.
              </Trans>
            </h4>

            <div style={{ display: "block", textAlign: "center" }}>
              <button
                className="primaryButton"
                onClick={() => login("/my-cards")}
              >
                <Trans i18nKey="myo_landing_action_button">Get started</Trans>
              </button>
              <a
                rel="noopener noreferrer"
                href={this.props.i18n.t(
                  "myo_help_url",
                  "https://support.yotoplay.com/en-US/make-your-own-cards-overview-11556",
                )}
                target="_blank"
              >
                <button className="textButtonLink">
                  <Trans i18nKey="myo_landing_help_link_text">
                    How it works
                  </Trans>
                </button>
              </a>
            </div>
            <br />
          </div>
        </div>
      </>
    );
  }
}

Create.propTypes = propTypes;

export default withTranslation()(Create);

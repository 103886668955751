import PropTypes from "prop-types";
import React from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import { deleteCard } from "../utils/yoto-api";
import { browserHistory } from "react-router";
import { Trans, withTranslation } from "react-i18next";
import { DangerButton } from "./Button";

const MY_CARDS_URL = "/my-cards/playlists";
const MY_PODCASTS_URL = "/my-cards/podcasts";

const CARD_TYPE_PODCAST = "podcast";

export const DeleteCardButtonProps = {
  i18n: PropTypes.object,
  cardType: PropTypes.string,
  cardId: PropTypes.string,
  cardTitle: PropTypes.string,
  onDeleted: PropTypes.func,
};

function DeleteCardButton({ i18n, cardId, cardTitle, cardType }) {
  const [open, setOpen] = React.useState(false);
  const [working, setWorking] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setWorking(true);
    await deleteCard(cardId);
    //await new Promise(r => setTimeout(r, 2000));
    setWorking(false);
    if (cardType === CARD_TYPE_PODCAST) {
      browserHistory.push(MY_PODCASTS_URL);
    } else {
      browserHistory.push(MY_CARDS_URL);
    }
  };

  //const t = this.props.i18n.t;

  return (
    <div>
      <DangerButton
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        {cardType === CARD_TYPE_PODCAST
          ? i18n.t("myo_edit_delete_podcast_button", "Delete Podcast")
          : `${i18n.t("myo_edit_delete_playlist_button", "Delete Playlist")}`}
      </DangerButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {i18n.t(
            "myo_edit_delete_confirm_title",
            `Are you sure you want to delete "${cardTitle}"?`,
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Trans key="myo_edit_delete_confirm_text">
              This will delete the content from Yoto and from any linked card.
              This cannot be undone.
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ opacity: working ? 0 : 1 }}
            onClick={handleClose}
            color="secondary"
            autoFocus
            disabled={working}
          >
            <Trans key="common_cancel">Cancel</Trans>
          </Button>
          <Button onClick={handleDelete} color="primary" disabled={working}>
            {!working
              ? i18n.t("common_yes_delete", "Yes, Delete")
              : i18n.t("common_deleting", "Deleting...")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteCardButton.propTypes = DeleteCardButtonProps;

export default withTranslation()(DeleteCardButton);

import { Container } from "@material-ui/core";
import React from "react";

/**
 * @type React.FC<>
 */
// eslint-disable-next-line react/prop-types
const Page = ({ children }) => {
  return <Container fluid>{children}</Container>;
};

export default Page;

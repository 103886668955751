/* eslint-disable i18next/no-literal-string */
import React, { Component } from "react";
import "../index.css";

class MobileHome extends Component {
  render() {
    return (
      <div>
        Your Yoto app version is out of date. Please update the app to the
        latest version found in the app store.
      </div>
    );
  }
}

export default MobileHome;

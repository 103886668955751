/* eslint-disable i18next/no-literal-string */
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import Create from "./components/Create";
import EditCard2 from "./components/EditCard2";
import DeviceLibrary3 from "./components/DeviceLibrary3";
import FamilyLibrary from "./components/FamilyLibrary";
import DeviceConfig from "./components/DeviceConfig";
import { allowSso, requireAuth } from "./utils/AuthService";
import { Router, Route, browserHistory } from "react-router";
import Callback from "./components/Callback";
import Home from "./components/Home";
import * as Sentry from "@sentry/browser";
import UserCards from "./components/UserCards";
import MobileHome from "./components/MobileHome";
import Player from "./components/Player";
import CardPreview from "./components/CardPreview";
import DebugTools from "./components/DebugTools";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "./i18n";
import BirthdayShoutoutForm from "./components/BirthdayShoutoutForm";
import EditPodcast from "./components/EditPodcast";
import DeviceBetaConfig from "./components/DeviceBetaConfig";
import "bootstrap/dist/css/bootstrap.css";

ReactGA.initialize("UA-104706573-2");

Sentry.init({
  dsn: "https://a9fcd1cec499431695fbbdd0f29da3cd@sentry.io/1363192",
  environment: window.location.hostname,
});

function logPageView() {
  //do not leak signed card URLs (player should rewrite URL to exclude sensitive information at which point another route onUpdate will get triggered and page view logged)
  if (
    window.location.search.includes(
      "https%3A%2F%2Fapi.yotoplay.com%2Fcard%2Fresolve%2F",
    )
  )
    return;
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#F95E3F",
      dark: "#dc492e",
    },
    secondary: {
      main: "#003c1f",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const Root = () => {
  return (
    <div className="content">
      <Router history={browserHistory} onUpdate={logPageView}>
        <Route path="/" component={Home} onEnter={allowSso} />
        <Route path="/mobile-home" component={MobileHome} onEnter={allowSso} />
        <Route path="/create" component={Create} onEnter={allowSso} />
        <Route path="/ios" component={Home} onEnter={allowSso} />
        <Route path="/android" component={Home} onEnter={allowSso} />
        <Route path="/my-cards" component={UserCards} onEnter={requireAuth} />
        <Route
          path="/my-cards/:selectedTab"
          component={UserCards}
          onEnter={requireAuth}
        />
        <Route path="/play" component={Player} onEnter={requireAuth} />
        <Route path="/s/:shareToken" component={Player} onEnter={allowSso} />
        <Route path="/preview" component={CardPreview} onEnter={allowSso} />
        <Route
          path="/yoto/:deviceId/beta"
          component={DeviceBetaConfig}
          onEnter={allowSso}
        />
        <Route
          path="/yoto/:deviceId/myo"
          component={DeviceLibrary3}
          onEnter={allowSso}
        />
        <Route
          path="/yoto/:deviceId/library"
          component={DeviceLibrary3}
          onEnter={allowSso}
        />
        <Route
          path="/yoto/:deviceId/library-beta"
          component={DeviceLibrary3}
          onEnter={allowSso}
        />
        <Route
          path="/yoto/:deviceId/library-v3"
          component={DeviceLibrary3}
          onEnter={allowSso}
        />
        <Route path="/library" component={FamilyLibrary} onEnter={allowSso} />
        <Route
          path="/yoto/:deviceId/config"
          component={DeviceConfig}
          onEnter={allowSso}
        />

        <Route path="/card/edit" component={EditCard2} onEnter={requireAuth} />
        <Route
          path="/card/:cardId/edit"
          component={EditCard2}
          onEnter={requireAuth}
        />
        <Route
          path="/card/edit-v2"
          component={EditCard2}
          onEnter={requireAuth}
        />
        <Route
          path="/card/:cardId/edit-v2"
          component={EditCard2}
          onEnter={requireAuth}
        />
        <Route
          path="/card/edit-podcast"
          component={EditPodcast}
          onEnter={requireAuth}
        />
        <Route
          path="/card/:cardId/edit-podcast"
          component={EditPodcast}
          onEnter={requireAuth}
        />

        <Route path="/debug" component={DebugTools} />
        <Route
          path="/request-a-birthday-shoutout"
          component={BirthdayShoutoutForm}
        />

        <Route path="/callback" component={Callback} />
        <Route
          path="/android/yoto/:deviceId/library"
          component={DeviceLibrary3}
          onEnter={allowSso}
        />
      </Router>
    </div>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Root />
  </MuiThemeProvider>,
  document.getElementById("root"),
);

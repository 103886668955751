import React, { Component } from "react";
import { Link } from "react-router";
import "../App.css";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LangSelector from "./LangSelector";

const YOTO_LOGO_ALT = "Yoto Logo";

const styles = {
  root: {
    //flexGrow: 1,
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  menu: {
    backgroundColor: "#F85E41",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

const TAB_PLAYLISTS_KEY = "playlists";
const TAB_PODCASTS_KEY = "podcasts";
const TAB_BASE_URL = "/my-cards/";

const PAGE_TITLE_PREFIX = "Yoto -";

class PageHeaderMyoMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedTab: props.selected };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      document.selected = this.props.selected;
    }
  }

  onTabSelected(selectedTab) {
    this.setState({ selectedTab });
    if (this.props.onTabSelected) this.props.onTabSelected(selectedTab);
  }
  render() {
    const tabs = [
      {
        key: TAB_PLAYLISTS_KEY,
        title: this.props.i18n.t("myo_tab_playlists", "Playlists"),
        url: `${TAB_BASE_URL}${TAB_PLAYLISTS_KEY}`,
      },
      {
        key: TAB_PODCASTS_KEY,
        title: this.props.i18n.t("myo_tab_podcasts", "Podcasts"),
        url: `${TAB_BASE_URL}${TAB_PODCASTS_KEY}`,
      },
    ];

    if (this.props.title)
      document.title = `${PAGE_TITLE_PREFIX} ${this.props.title}`;

    return (
      <div>
        <div>
          <AppBar position="static">
            <Toolbar style={{ position: "relative" }}>
              <ul className="header-list">
                <li className="menu-item" style={{ paddingTop: "2px" }}>
                  <img
                    alt={YOTO_LOGO_ALT}
                    className="header-logo-mobile"
                    src="https://www.datocms-assets.com/48136/1633096024-face-3x.png"
                  />
                </li>
                <li className="menu-item">
                  <img
                    alt={YOTO_LOGO_ALT}
                    className="header-logo-desktop"
                    src="https://www.datocms-assets.com/48136/1633095990-logotype-3x.png"
                  />
                </li>
                {tabs.map((tab) => (
                  <li className="menu-item" key={`menu-${tab.key}`}>
                    <Link
                      to={tab.url}
                      key={tab.key}
                      className={`header-string ${
                        this.props.selected === tab.key
                          ? "header-string-active"
                          : ""
                      }`}
                      onClick={() => this.onTabSelected(tab.key)}
                    >
                      {tab.title}
                    </Link>
                  </li>
                ))}
                <li style={{ float: "right" }}>
                  <LangSelector i18n={this.props.i18n} />
                </li>
              </ul>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    );
  }
}

PageHeaderMyoMenu.propTypes = {
  tabs: PropTypes.array,
  selected: PropTypes.string,
  onTabSelected: PropTypes.func,
  title: PropTypes.string,
  i18n: PropTypes.object,
};

export default withStyles(styles)(PageHeaderMyoMenu);

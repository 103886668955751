/* eslint-disable i18next/no-literal-string */
import React, { Component } from "react";
import "../index.css";

import { Trans, withTranslation } from "react-i18next";
import "moment/locale/de";
import "moment/locale/fr";

class DeviceConfigV2 extends Component {
  render() {
    return (
      <div>
        <div style={{ width: "100%", textAlign: "center", padding: "2em" }}>
          <p>
            <b>
              <Trans i18nKey="settings_update_app">
                Please update to the latest version of the Yoto App.
              </Trans>
            </b>
          </p>
          <p>
            <Trans i18nKey="settings_not_supported">
              Player Settings are no longer supported in this version.
            </Trans>
          </p>
        </div>{" "}
        :
      </div>
    );
  }
}

DeviceConfigV2.propTypes = {};

export default withTranslation()(DeviceConfigV2);

import React, { Component } from "react";
import {
  getDeviceConfigV1,
  updateDeviceConfigV1,
  getDeviceStatus,
  sendDeviceCommand,
} from "../utils/yoto-api";
//import semver from 'semver'
import { login, isLoggedIn, isAdmin } from "../utils/AuthService";
import "../index.css";
import { browserHistory } from "react-router";
import queryString from "qs";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

import PlayIcon from "@material-ui/icons/PlayCircleOutline";
import PauseIcon from "@material-ui/icons/PauseCircleOutline";
import ClockIcon from "@material-ui/icons/AccessTime";
import HomeIcon from "@material-ui/icons/Home";
import BellIcon from "@material-ui/icons/Notifications";
import DayIcon from "@material-ui/icons/WbSunny";
import NightIcon from "@material-ui/icons/Brightness3";
import BedIcon from "@material-ui/icons/Hotel";
import BreakfastIcon from "@material-ui/icons/FreeBreakfast";
//import ButtonIcon from "@material-ui/icons/HdrWeak";
import TagIcon from "@material-ui/icons/LocalOffer";
import ChipIcon from "@material-ui/icons/Memory";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";

import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Switch from "@material-ui/core/Switch/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input/Input";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";

const PREVIEWS_URL =
  "https://static.yotoplay.com/device-config/sound-previews/";

class DeviceConfigV1 extends Component {
  constructor() {
    super();
    //this.state = { devices: [] };
    this.player = new Audio();

    //Detect end of audio
    this.player.addEventListener("ended", () => {
      console.log("player ended");
      this.setState({ playingTrackId: null });
    });
    this.player.addEventListener(
      "error",
      () => {
        console.log("player error");
        this.setState({ playingTrackId: null });
      },
      false,
    );
    /*        this.player.addEventListener('abort', () => {
            console.log('player abort');
            this.setState({playingTrackId: null})
        }, false);*/

    this.state = {
      isAdmin: isAdmin(),
      deviceConfig: null,
      updatedConfig: { config: {} },
      working: false,
      deviceOnline: null,
      maxVolume: null,
    };
  }

  componentDidMount() {
    if (!isLoggedIn()) {
      login(this.props.location.pathname);
      //replace({pathname: '/'});
    }
    this.deviceId = this.props.params.deviceId;
    const qs = queryString.parse(this.props.location.search.split("?")[1]);
    //console.log(qs)
    if (qs.os) this.setState({ os: qs.os.toLowerCase() });

    //console.log("deviceLibrary.componentDidMount");
    getDeviceConfigV1(this.deviceId).then((deviceConfig) => {
      this.setState({ deviceConfig });
    });

    getDeviceStatus(this.deviceId)
      .then((device) => {
        if (device.status) {
          this.setState({ maxVolume: device.status.maxVolume });
        }
        this.setState({ deviceOnline: device.online });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateName = (event) => {
    const { deviceConfig, updatedConfig } = this.state;
    console.log(event.target.name);
    console.log(event.target.value);
    deviceConfig.name = event.target.value;
    updatedConfig.name = event.target.value;
    this.setState({ deviceConfig, updatedConfig });
  };

  updateConfig = (event) => {
    const { deviceConfig, updatedConfig } = this.state;
    //    console.log(event.target.name);
    //    console.log(event.target.value);
    deviceConfig.config[event.target.name] = event.target.value;
    updatedConfig.config[event.target.name] = event.target.value;
    this.setState({ deviceConfig, updatedConfig });
  };

  handleTimeChange = (dayPart, date) => {
    //        console.log(dayPart);
    //        console.log(date);
    const { deviceConfig, updatedConfig } = this.state;
    deviceConfig.config[dayPart] = date.format("HH:mm");
    updatedConfig.config[dayPart] = deviceConfig.config[dayPart];
    if (deviceConfig.config.dayTime > deviceConfig.config.nightTime) {
      //            console.log(deviceConfig.config.dayTime);
      //            console.log(deviceConfig.config.nightTime);
      if (dayPart === "dayTime") {
        deviceConfig.config.dayTime = deviceConfig.config.nightTime;
      } else {
        deviceConfig.config.nightTime = deviceConfig.config.dayTime;
      }
    }
    this.setState({ deviceConfig, updatedConfig });
  };

  updateMaxVolume = (event, newValue) => {
    if (!newValue) return;
    sendDeviceCommand(this.deviceId, "set-volume", {
      volume: parseInt(event.target.value),
      maxVolume: parseInt(event.target.value),
    });
    this.setState({ maxVolume: event.target.value });
  };

  applyConfig = () => {
    this.setState({ working: true });
    updateDeviceConfigV1(this.deviceId, this.state.updatedConfig)
      .then(() => {
        //this.log("card write done");
        sendDeviceCommand(this.deviceId, "restart", {}).then(() => {
          this.setState({ working: false });
          this.setState({ saveDialogOpen: false });
          browserHistory.goBack();
        });
      })
      .catch((err) => {
        console.log(err);
        //this.log("card write fail " + JSON.stringify(err.response));
      });
  };

  playTrack(trackId, trackUrl) {
    if (this.state.playingTrackId === trackId || !trackUrl) {
      this.player.pause();
      this.player.src = null;
      this.setState({ playingTrackId: null });
    } else {
      this.player.src = trackUrl;
      this.player.play();
      this.setState({ playingTrackId: trackId });
    }
  }

  toggleConfig = (option) => {
    const { deviceConfig, updatedConfig } = this.state;
    switch (option) {
      case "hourFormat":
        if (deviceConfig.config.hourFormat === "24") {
          deviceConfig.config.hourFormat = "12";
        } else {
          deviceConfig.config.hourFormat = "24";
        }
        updatedConfig.config.hourFormat = deviceConfig.config.hourFormat;
        break;
      default:
    }
    this.setState({ deviceConfig, updatedConfig });
  };

  render() {
    const { deviceConfig, working, playingTrackId, os, maxVolume } = this.state;
    const canPlayAudio =
      os !== "ios" ||
      (this.props.location &&
        this.props.location.query &&
        this.props.location.query.appVersion);

    return (
      <div>
        {!deviceConfig ? (
          <div style={{ width: "100%", textAlign: "center", padding: "2em" }}>
            Loading settings...
          </div>
        ) : (
          <div style={{ maxWidth: "550px" }}>
            {deviceConfig ? (
              <div>
                <List
                  subheader={<ListSubheader disableSticky>About</ListSubheader>}
                >
                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <TagIcon />
                    </ListItemIcon>
                    <ListItemText primary="Player name" />
                    <ListItemSecondaryAction>
                      <Input
                        value={deviceConfig.name}
                        onChange={this.updateName}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <VolumeUpIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Maximum volume"
                      /*secondary={!deviceOnline ?
                                                    "Unavailable when Player offline" :
                                                    ""
                                                }*/
                    />
                    <ListItemSecondaryAction>
                      <FormControl /*disabled={!deviceOnline}*/>
                        <Select
                          autoWidth={true}
                          value={Math.round(maxVolume / 20) * 20}
                          onChange={this.updateMaxVolume}
                          inputProps={{
                            name: "maxVolume",
                            id: "maxVolume",
                          }}
                        >
                          <MenuItem value="" />
                          <MenuItem value="20">20%</MenuItem>
                          <MenuItem value="40">40%</MenuItem>
                          <MenuItem value="60">60%</MenuItem>
                          <MenuItem value="80">80%</MenuItem>
                          <MenuItem value="100">100%</MenuItem>
                        </Select>
                      </FormControl>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <ChipIcon />
                    </ListItemIcon>
                    <ListItemText primary="Software version" />
                    <ListItemSecondaryAction>
                      {deviceConfig.appVersion}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>

                <List
                  subheader={<ListSubheader disableSticky>Clock</ListSubheader>}
                >
                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clock face" />
                    <ListItemSecondaryAction>
                      <Select
                        autoWidth={true}
                        value={deviceConfig.config.clockFace}
                        onChange={this.updateConfig}
                        inputProps={{
                          name: "clockFace",
                          id: "clockFace",
                        }}
                      >
                        <MenuItem value="digital-sun">
                          <img
                            style={{
                              width: "32px",
                              borderRadius: "6px",
                              backgroundColor: "#ccc",
                              padding: "4px",
                              marginRight: "6px",
                              marginTop: "-2px",
                            }}
                            alt="Clock face preview"
                            src={"/img/clock-face-digital-sun.png"}
                          />
                          Digital with sun
                        </MenuItem>
                        <MenuItem value="sun">
                          <img
                            style={{
                              width: "32px",
                              borderRadius: "6px",
                              backgroundColor: "#ccc",
                              padding: "4px",
                              marginRight: "6px",
                              marginTop: "-2px",
                            }}
                            alt="Clock face preview"
                            src={"/img/clock-face-sun.png"}
                          />
                          Sun and moon
                        </MenuItem>
                        <MenuItem value="circles">
                          <img
                            style={{
                              width: "32px",
                              borderRadius: "6px",
                              backgroundColor: "#ccc",
                              padding: "4px",
                              marginRight: "6px",
                              marginTop: "-2px",
                            }}
                            alt="Clock face preview"
                            src={"/img/clock-face-circle.png"}
                          />
                          Geometric
                        </MenuItem>
                        <MenuItem value="digital-small">
                          <img
                            style={{
                              width: "32px",
                              borderRadius: "6px",
                              backgroundColor: "#ccc",
                              padding: "4px",
                              marginRight: "6px",
                              marginTop: "-2px",
                            }}
                            alt="Clock face preview"
                            src={"/img/clock-face-digital.png"}
                          />
                          Simple digital
                        </MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <ClockIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="24-hour clock"
                      secondary={
                        deviceConfig.config.hourFormat === "24"
                          ? "Digital clock will display 18.30"
                          : "Digital clock will display 6.30"
                      }
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={() => {
                          this.toggleConfig("hourFormat");
                        }}
                        checked={deviceConfig.config.hourFormat === "24"}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <BellIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hourly chime" />
                    <ListItemSecondaryAction>
                      {!canPlayAudio ? (
                        ""
                      ) : "chimes" === playingTrackId ? (
                        <IconButton
                          aria-label="Stop"
                          onClick={() => {
                            this.playTrack("chimes", null);
                          }}
                          color="primary"
                        >
                          <PauseIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="Preview"
                          disabled={!deviceConfig.config.chimes}
                          onClick={() => {
                            this.playTrack(
                              "chimes",
                              PREVIEWS_URL +
                                deviceConfig.config.chimes +
                                ".m4a",
                            );
                          }}
                          color="primary"
                        >
                          <PlayIcon />
                        </IconButton>
                      )}
                      <Select
                        displayEmpty={true}
                        autoWidth={true}
                        value={deviceConfig.config.chimes}
                        onChange={this.updateConfig}
                        inputProps={{
                          name: "chimes",
                          id: "chimes",
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="small-clock">
                          Small clock chimes
                        </MenuItem>
                        <MenuItem value="casio">'80s watch beep</MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>

                <List
                  subheader={<ListSubheader disableSticky>Day</ListSubheader>}
                >
                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <DayIcon />
                    </ListItemIcon>
                    <ListItemText primary="Day start" />
                    <ListItemSecondaryAction>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <TimePicker
                          ampm={deviceConfig.config.hourFormat !== "24"}
                          autoOk={true}
                          value={
                            "2000-01-01 " +
                            ("0" + deviceConfig.config.dayTime).substr(-5, 5)
                          }
                          onChange={(date) =>
                            this.handleTimeChange("dayTime", date)
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <BreakfastIcon />
                    </ListItemIcon>
                    <ListItemText primary="Morning chime" />
                    <ListItemSecondaryAction>
                      {!canPlayAudio ? (
                        ""
                      ) : "morningSound" === playingTrackId ? (
                        <IconButton
                          aria-label="Stop"
                          onClick={() => {
                            this.playTrack("morningSound", null);
                          }}
                          color="primary"
                        >
                          <PauseIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="Preview"
                          disabled={!deviceConfig.config.morningSound}
                          onClick={() => {
                            this.playTrack(
                              "morningSound",
                              PREVIEWS_URL +
                                deviceConfig.config.morningSound +
                                ".m4a",
                            );
                          }}
                          color="primary"
                        >
                          <PlayIcon />
                        </IconButton>
                      )}
                      <Select
                        displayEmpty={true}
                        autoWidth={true}
                        value={deviceConfig.config.morningSound}
                        onChange={this.updateConfig}
                        inputProps={{
                          name: "morningSound",
                          id: "morningSound",
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="digital-alarm">
                          Digital alarm clock
                        </MenuItem>
                        <MenuItem value="rooster">Cock-a-doodle-doo</MenuItem>
                        <MenuItem value="school-bell">School bell</MenuItem>
                        <MenuItem value="dawn-chorus">
                          Dawn birds chorus
                        </MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>

                <List
                  subheader={<ListSubheader disableSticky>Night</ListSubheader>}
                >
                  <ListItem>
                    <ListItemIcon>
                      <NightIcon
                        style={{ marginRight: 0, transform: "rotate(160deg)" }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Night time" />
                    <ListItemSecondaryAction>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <TimePicker
                          ampm={deviceConfig.config.hourFormat !== "24"}
                          autoOk={true}
                          okLabel={<span />}
                          value={
                            "2000-01-01 " +
                            ("0" + deviceConfig.config.nightTime).substr(-5, 5)
                          }
                          onChange={(date) =>
                            this.handleTimeChange("nightTime", date)
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginRight: 0 }}>
                      <BedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Goodnight chime" />
                    <ListItemSecondaryAction>
                      {!canPlayAudio ? (
                        ""
                      ) : "eveningSound" === playingTrackId ? (
                        <IconButton
                          aria-label="Stop"
                          onClick={() => {
                            this.playTrack("eveningSound", null);
                          }}
                          color="primary"
                        >
                          <PauseIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="Preview"
                          disabled={!deviceConfig.config.eveningSound}
                          onClick={() => {
                            this.playTrack(
                              "eveningSound",
                              PREVIEWS_URL +
                                deviceConfig.config.eveningSound +
                                ".m4a",
                            );
                          }}
                          color="primary"
                        >
                          <PlayIcon />
                        </IconButton>
                      )}
                      <Select
                        displayEmpty={true}
                        autoWidth={true}
                        value={deviceConfig.config.eveningSound}
                        onChange={this.updateConfig}
                        inputProps={{
                          name: "eveningSound",
                          id: "eveningSound",
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="yawn">Sleepy yawn</MenuItem>
                        <MenuItem value="church-bell">
                          Church bell dong
                        </MenuItem>
                        <MenuItem value="owl">Owls hooting</MenuItem>
                        <MenuItem value="music-box">Music-box lullaby</MenuItem>
                      </Select>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>

                {this.state.isAdmin ? (
                  <div>
                    <h5>Admin only options</h5>
                    <div>
                      Display mode:
                      <span>
                        <select
                          name="displayMode"
                          value={deviceConfig.config.displayMode}
                          onChange={this.updateConfig}
                        >
                          <option value="colour">Colour</option>
                          <option value="monochrome">Monochrome</option>
                        </select>
                      </span>
                    </div>

                    <div>
                      Volume indicator:
                      <span>
                        <select
                          name="volumeIndicator"
                          value={deviceConfig.config.volumeIndicator}
                          onChange={this.updateConfig}
                        >
                          <option value="simple">classic</option>
                          <option value="colour">colour</option>
                        </select>
                      </span>
                    </div>

                    <div>
                      Clock speed burst:
                      <span>
                        <select
                          name="timeBurst"
                          value={deviceConfig.config.timeBurst}
                          onChange={this.updateConfig}
                        >
                          <option value="1">Off</option>
                          <option value="20">20x</option>
                          <option value="100">100x</option>
                          <option value="200">200x</option>
                          <option value="500">500x</option>
                          <option value="1000">1000x</option>
                        </select>
                      </span>
                    </div>

                    <div>
                      Clock face transition period:
                      <span>
                        <select
                          name="transitionMinutes"
                          value={deviceConfig.config.transitionMinutes}
                          onChange={this.updateConfig}
                        >
                          <option value="0">&lt;default&gt;</option>
                          <option value="1">1 minute</option>
                          <option value="5">5 minutes</option>
                          <option value="15">15 minutes</option>
                          <option value="30">30 minutes</option>
                          <option value="60">1 hour</option>
                          <option value="120">2 hours</option>
                          <option value="180">3 hours</option>
                        </select>
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div />
            )}
            <div style={{ textAlign: "center" }}>
              <Button
                variant="outlined"
                style={{ margin: "8px" }}
                onClick={browserHistory.goBack}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ margin: "8px" }}
                color="primary"
                onClick={() => {
                  this.setState({ saveDialogOpen: true });
                }}
              >
                Apply settings
              </Button>
            </div>
            <br />
            <div className="cardLibSpacer" />

            <Dialog
              fullWidth={true}
              open={!!this.state.saveDialogOpen}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Yoto will restart
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Your Yoto will briefly restart to apply these changes. We'll
                  be back up and running in a minute or so.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={working}
                  onClick={() => {
                    this.setState({ saveDialogOpen: false });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={working}
                  onClick={this.applyConfig}
                  color="primary"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

export default DeviceConfigV1;

import React, { useState } from "react";
import { useAsync } from "react-use";
import axios from "axios";

/* eslint-disable i18next/no-literal-string */

export default () => (
  <main>
    <h1>Debug Tools</h1>
    <ReportCurrentIP />
    <TestHTTPApiConnection />
    <MQTTConnection />
    <ReportConnectionToPlayer />
  </main>
);

function TestHTTPApiConnection() {
  const [invalidationToken, invalidate] = useState(Date.now());
  const { error } = useAsync(async () => {
    return axios.get("https://api.yotoplay.com/auth/echo/userAuthorizer", {
      headers: { Accept: "application/json" },
    });
  }, [invalidationToken]);

  return (
    <section>
      <h3>
        API connection status : {error && error.response ? "Good" : "Bad"}
      </h3>
      <p>{error && error.message}</p>
      <button onClick={() => invalidate(Date.now())}>refresh</button>
    </section>
  );
}
function MQTTConnection() {
  return (
    <section>
      <h3>MQTT connection status : Bad</h3>
    </section>
  );
}

function ReportCurrentIP() {
  const [invalidationToken, invalidate] = useState(Date.now());
  const { value } = useAsync(async () => {
    return axios.get("https://ifconfig.co", {
      headers: { Accept: "application/json" },
    });
  }, [invalidationToken]);

  return (
    <section>
      <h3>General</h3>
      {value && <p>IP: {value.data.ip}</p>}
      {value && (
        <p>
          Location: {value.data.country} {value.data.city}
        </p>
      )}
      {value && <p>ISP: {value.data.asn_org}</p>}
      <button onClick={() => invalidate(Date.now())}>refresh</button>
    </section>
  );
}

function ReportConnectionToPlayer() {
  return <section>{/*<h3>Player Connection : Bad</h3>*/}</section>;
}

import React, { Component } from "react";
//import {getMyDevices} from "../utils/yoto-api";
//import {setAccessToken} from "../utils/AuthService";

const MAIN_HOMEPAGE_URL = "/create";

class Home extends Component {
  componentDidMount() {
    //console.log("HOME")
    window.location = MAIN_HOMEPAGE_URL;
  }

  render() {
    return <div />;
  }
}

export default Home;

import React, { Component } from "react";
import { resolveCard } from "../utils/yoto-api";
//import {setAccessToken} from "../utils/AuthService";
import { browserHistory } from "react-router";
import "../playerStyle.css";
import { isIOS, isAndroid } from "react-device-detect";

class CardPreview extends Component {
  constructor() {
    super();
    //this.state = { devices: [] };
    this.player = new Audio();

    //Detect end of audio
    this.player.addEventListener("ended", () => {
      //console.log('player ended');
      this.setState({ playing: false });
    });
    this.player.addEventListener(
      "error",
      (err) => {
        console.log(`player error: ${JSON.stringify(err)}`);
        this.setState({ playing: false });
      },
      false,
    );
    this.player.addEventListener(
      "abort",
      () => {
        //console.log('player abort');
        this.setState({ playing: false });
      },
      false,
    );

    this.state = { playing: false };
  }

  async componentDidMount() {
    let card;
    this.setState({ card: null, cardStatus: "loading" });
    try {
      console.log(this.props.location.query.cardUrl);
      if (this.props.location.query && this.props.location.query.cardUrl)
        card = await resolveCard(this.props.location.query.cardUrl);
    } catch (err) {
      console.log(err);
    }
    console.log(card);
    if (!card || !card.content) {
      browserHistory.replace("/preview");
      this.setState({ card: null, cardStatus: "invalid-card" });
    } else {
      browserHistory.replace(
        `/preview?cardUrl=https%3A%2F%2Fyoto.io%2F${card.cardId}`,
      );
      this.setState({ card, cardStatus: "loaded" });
    }
  }

  playButtonClick() {
    const card = this.state.card;
    if (!card || !card.metadata || !card.metadata.previewAudio) return;
    if (this.state.playing) {
      this.stopPlayback();
    } else {
      this.playPreview();
    }
  }

  /*
    insertCard() {
        this.setState({ cardStatus: 'inserted' });
        this.playPreview(0, 0);
    }

    removeCard() {
        this.stopPlayback();
        this.setState({ cardStatus: 'loaded', playingChapterPos: null, selectedChapterPos: 0 });
    }
    */

  stopPlayback() {
    this.player.pause();
    this.player.currentTime = 0;
    this.setState({ playing: false });
  }

  async playPreview() {
    try {
      this.player.src = this.state.card.metadata.previewAudio;
      await this.player.play();
      this.setState({ playing: true });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { card, cardStatus, playing } = this.state;

    const cardLoaded = card && ["loaded", "inserted"].includes(cardStatus);

    /*
        let cardTitle

        switch (cardStatus) {
            case 'loaded':
            case 'inserted':
                cardTitle = card ? card.title : '';
                break;
            case 'loading':
                cardTitle = "Loading card...";
                break;
            case 'invalid-card':
                cardTitle = "Failed to load card.";
                break;
            default:
                cardTitle = '';
        } */

    return [
      <div className="playerBody" style={{ textAlign: "center" }}>
        {cardLoaded ? (
          <div className="cardholder">
            <div className="card">
              <h3>{card.title}</h3>
              <h4>{card.metadata.author}</h4>
              <img
                src={
                  card.content.cover
                    ? card.content.cover.imageL
                    : "img/blank-card.png"
                }
                alt="Card cover"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          style={
            cardLoaded && card.metadata && card.metadata.previewAudio
              ? {}
              : { display: "none" }
          }
        >
          <div className="player-area">
            <span
              className={playing ? "pause-btn" : "play-btn"}
              onClick={() => this.playButtonClick()}
            ></span>
          </div>
        </div>

        <div>
          {!isAndroid ? (
            <img
              src="/img/app-store.png"
              alt="Download on App Store"
              style={{ width: "300px" }}
            />
          ) : (
            ""
          )}
          {!isIOS ? (
            <img
              src="/img/play-store.png"
              alt="Get it on Google Play"
              style={{ width: "300px" }}
            />
          ) : (
            ""
          )}
        </div>
      </div>,
    ];
  }
}

export default CardPreview;

import React, { Component } from "react";
import { resolveCard } from "../utils/yoto-api";
//import {setAccessToken} from "../utils/AuthService";
import { browserHistory } from "react-router";
import "../playerStyle.css";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Trans, withTranslation } from "react-i18next";

const moment = require("moment");

const CARD_STATUS_LOADING = "loading";
const CARD_STATUS_LOADED = "loaded";
const CARD_STATUS_INVALID = "invalid-card";

const formatDuration = (seconds) => {
  const DURATION_FORMAT = "mm:ss"; //https://stackoverflow.com/a/34869030
  return moment().startOf("day").seconds(seconds).format(DURATION_FORMAT);
};

class Player extends Component {
  constructor(props) {
    super(props);
    this.player = new Audio();

    //Detect end of audio
    this.player.addEventListener("ended", () => {
      console.log("player ended");
      this.playNextTrack();
    });
    this.player.addEventListener(
      "error",
      (err) => {
        console.log(`player error: ${JSON.stringify(err)}`);
        this.setState({ playingTrackId: null, cardStatus: null });
      },
      false,
    );
    /*        this.player.addEventListener('abort', () => {
                    console.log('player abort');
                    this.setState({playingTrackId: null})
                }, false);*/

    this.state = {
      playingChapterPos: null,
      playingTrackPos: null,
      selectedChapterPos: null,
    };
  }

  async componentDidMount() {
    let card;
    const shareToken = this.props.params.shareToken;
    let cardUrl =
      this.props.location.query && this.props.location.query.cardUrl;
    this.setState({
      card: null,
      cardStatus: CARD_STATUS_LOADING,
      selectedChapterPos: null,
    });
    console.log(this.props.location);
    console.log(this.props.params);
    if (this.props.location.pathname === `/s/${shareToken}`) {
      console.log("Share");
      cardUrl = `https://yoto.io${this.props.location.pathname}`;
    }
    try {
      if (cardUrl) card = await resolveCard(cardUrl);
    } catch (err) {
      console.log(err);
    }
    if (
      !card ||
      !card.content ||
      !card.content.chapters ||
      card.content.chapters.length === 0
    ) {
      //      browserHistory.replace('/play');
      this.setState({
        card: null,
        cardStatus: CARD_STATUS_INVALID,
        selectedChapterPos: null,
      });
    } else {
      if (!card.sharing && !shareToken) {
        browserHistory.replace(
          `/play?cardUrl=https%3A%2F%2Fyoto.io%2F${card.slug}`,
        );
      } else if (card.sharing && !shareToken) {
        browserHistory.replace(card.sharing.linkUrl);
      }
      this.setState({
        card,
        cardStatus: CARD_STATUS_LOADED,
        selectedChapterPos: 0,
      });
    }
  }

  playButtonClick() {
    if (this.state.cardStatus !== CARD_STATUS_LOADED) return;
    if (this.state.playingTrackPos != null) {
      this.stopPlayback();
    } else {
      if (this.state.selectedChapterPos == null)
        this.setState({ selectedChapterPos: 0 });
      this.playTrack(0, 0);
    }
  }

  playNextChapter() {
    const nextChapter =
      this.state.playingChapterPos < this.state.card.content.chapters.length - 1
        ? this.state.playingChapterPos + 1
        : 0;
    this.setState({ selectedChapterPos: nextChapter });
    this.playTrack(nextChapter, 0);
  }

  playNextTrack() {
    const card = this.state.card;
    if (!card || !card.content || !card.content.chapters) return;
    const chapters = card.content.chapters;

    if (
      this.state.playingTrackPos + 1 >=
      chapters[this.state.playingChapterPos].tracks.length
    ) {
      this.playNextChapter();
    } else {
      this.playTrack(
        this.state.playingChapterPos,
        this.state.playingTrackPos + 1,
      );
    }
  }

  stopPlayback() {
    this.player.pause();
    this.player.currentTime = 0;
    //this.player.src = null;
    this.setState({ playingTrackPos: null, playingChapterPos: null });
  }

  playTrack(chapterPos, trackPos) {
    const chapter = this.state.card.content.chapters[chapterPos];
    const track = chapter.tracks[trackPos];
    this.player.src = track.trackUrl;
    this.player.play();
    this.setState({ playingChapterPos: chapterPos, playingTrackPos: trackPos });
  }

  categoryBorderRadius(category) {
    const CARD_BORDER_RADIUS_DEFAULT = "8px";
    const CARD_BORDER_RADIUS_PODCAST = "4px";
    const CARD_BORDER_RADIUS_RADIO = "500px";

    if (!category) return CARD_BORDER_RADIUS_DEFAULT;
    switch (category) {
      case "radio":
        return CARD_BORDER_RADIUS_RADIO;
      case "podcast":
        return CARD_BORDER_RADIUS_PODCAST;
      default:
        return CARD_BORDER_RADIUS_DEFAULT;
    }
  }

  render() {
    const { card, cardStatus, playingTrackPos, playingChapterPos } = this.state;
    const { i18n } = this.props;
    const sharingBlocked =
      card &&
      card.sharing &&
      card.sharing.shareLimit &&
      card.sharing.shareLimit <= card.sharing.shareCount;

    // eslint-disable-next-line i18next/no-literal-string
    const cardLoaded = card && cardStatus === CARD_STATUS_LOADED;

    let cardTitle;

    switch (cardStatus) {
      case CARD_STATUS_LOADED:
        cardTitle = card ? card.title : "";
        break;
      case CARD_STATUS_LOADING:
        cardTitle = "...";
        break;
      default:
        cardTitle = "";
    }
    const policyLink = i18n.t(
      "web_player_sharing_policy_url",
      "https://support.yotoplay.com/en-US/friends-and-family-safe-sharing-policy-11561",
    );

    return (
      <>
        <div className="playerBody" style={{ textAlign: "center" }}>
          <div className="cardholder">
            <div className="card">
              <img
                src={
                  cardLoaded && card.metadata.cover
                    ? card.metadata.cover.imageL
                    : "/img/MYO.png"
                }
                alt=""
                style={{
                  width: "100%",
                  borderRadius: this.categoryBorderRadius(
                    card && card.metadata && card.metadata.category,
                  ),
                }}
              />
            </div>
            <div className="player-controls">
              {sharingBlocked || cardStatus !== CARD_STATUS_LOADED ? (
                <span className="hidden-btn" />
              ) : (
                <span
                  style={{ display: sharingBlocked ? "none" : "" }}
                  className={playingTrackPos != null ? "pause-btn" : "play-btn"}
                  onClick={() => this.playButtonClick()}
                ></span>
              )}
            </div>
          </div>
          <div className="card-title">{cardTitle}</div>
          {cardStatus === CARD_STATUS_INVALID ? (
            <>
              <h5>
                <Trans i18nKey="web_player_invalid_link_title">
                  Unrecognized link
                </Trans>
              </h5>
              <p>
                <Trans i18nKey="web_player_invalid_link_desc">
                  It might contain a typo or is no longer available.
                </Trans>
              </p>
            </>
          ) : (
            ""
          )}

          {sharingBlocked ? (
            <>
              <h5>
                <Trans i18nKey="web_player_sharing_paused_title">
                  Sharing paused
                </Trans>
              </h5>
              <p>
                <Trans
                  link={policyLink}
                  i18nKey="web_player_sharing_paused_desc"
                >
                  We’ve frozen this link because it may be breaking our{" "}
                  <a href={policyLink}>sharing policy</a>. The playlist&#39;s
                  creator can email hello@yotoplay.com to request a manual
                  review.
                </Trans>
              </p>
            </>
          ) : (
            <>
              {!card || !card.creatorEmail ? (
                ""
              ) : (
                <div className="card-author">
                  <Trans i18nKey="web_player_content_created_by">
                    created by
                  </Trans>{" "}
                  <b>{card.creatorEmail}</b>
                </div>
              )}

              <Table
                size="small"
                style={{
                  maxWidth: "500px",
                  margin: "30px auto",
                  padding: "10px",
                }}
              >
                <TableBody>
                  {!card || !card.content
                    ? ""
                    : card.content.chapters.map((chapter, chapterPos) =>
                        chapter.tracks.map((track, trackPos) => {
                          const nowPlaying =
                            playingChapterPos === chapterPos &&
                            playingTrackPos === trackPos;
                          return (
                            <TableRow
                              selected={nowPlaying}
                              hover
                              onClick={() =>
                                nowPlaying
                                  ? this.stopPlayback()
                                  : this.playTrack(chapterPos, trackPos)
                              }
                              key={`${chapterPos}:${trackPos}`}
                            >
                              <TableCell
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1em",
                                  fontWeight: nowPlaying ? "bold" : "",
                                  borderBottom: "0",
                                  textAlign: "left",
                                }}
                              >
                                {track.overlayLabel ? (
                                  <span style={{ opacity: 0.5 }}>
                                    {track.overlayLabel}.{" "}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {track.title}
                              </TableCell>
                              <TableCell
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1em",
                                  fontWeight: nowPlaying ? "bold" : "",
                                  borderBottom: "0",
                                  textAlign: "left",
                                }}
                              >
                                {track.duration
                                  ? formatDuration(track.duration)
                                  : ""}
                              </TableCell>
                            </TableRow>
                          );
                        }),
                      )}
                </TableBody>
              </Table>
            </>
          )}

          <div className="cardLibSpacer" />

          <div
            style={cardLoaded ? {} : { display: "none" }}
            className="app-download-bar"
          >
            <div className="app-download-bar-content">
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={3} style={{ textAlign: "center" }}>
                  <img
                    src="/img/player/AppIcon.png"
                    alt=""
                    style={{ paddingTop: "10px" }}
                  />
                </Grid>
                <Grid item xs={9} style={{ textAlign: "left" }}>
                  <Trans i18nKey="web_player_download_app_tip">
                    Download the Yoto App to add to your library and listen on
                    Yoto Player.
                  </Trans>
                </Grid>
                <Grid item xs={6} style={{ padding: "0 0 20px 0" }}>
                  <a
                    href="https://apps.apple.com/app/yoto-music-stories-learning/id1412039719"
                    target="_app"
                  >
                    <img
                      style={{ paddingBottom: "4px" }}
                      src="/img/player/IOS.png"
                      alt={i18n.t(
                        "web_player_download_app_store",
                        "Download on the App Store",
                      )}
                    />
                  </a>
                </Grid>
                <Grid item xs={6} style={{ padding: "0 0 20px 0" }}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.yotoplay.yoto"
                    target="_app"
                  >
                    <img
                      style={{ paddingBottom: "4px" }}
                      src="/img/player/Android.png"
                      alt={i18n.t(
                        "web_player_download_google_play",
                        "Get it on Google Play",
                      )}
                    />
                  </a>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Player.propTypes = {
  location: PropTypes.object,
  params: PropTypes.object,
  i18n: PropTypes.object,
};

export default withTranslation()(Player);

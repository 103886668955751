import React, { Component } from "react";
import {
  addIconsToUser,
  getCard,
  getIconUrl,
  updateCard,
  uploadCoverImageFromUrl,
  getRSSPodcast,
} from "../utils/yoto-api";
import { browserHistory } from "react-router";
import ErrorBoundary from "./ErrorBoundary";
import PageHeader from "./PageHeaderMyoMenu";
import swal from "@sweetalert/with-react/dist/sweetalert";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import AutoLogout from "./AutoLogout";
import CoverImageDetail from "./card-edit/coverImageDetail";
import IconSelector from "./dialogs/IconSelector";
import { InputAdornment, TextField } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { PrimaryButton, SecondaryButton } from "./Button";
import { isLoggedIn, login } from "../utils/AuthService";
import * as _ from "lodash";
import DeleteCardButton from "./DeleteCardButton";

const MENU_TAB_PODCASTS = "podcasts";

const moment = require("moment");

const DEFAULT_ICON_MEDIA_ID = "jmpBDOfiG-5QDXND7NMAy9COAy1QJbM3AofqCy_BYX4";
const RSS_PODCAST_TYPE = "rss-podcast";
const CARD_CATEGORY_PODCAST = "podcast";

const propTypes = {
  location: PropTypes.object,
  readOnly: PropTypes.bool,
  params: PropTypes.object,
  i18n: PropTypes.object,
};

const DEFAULT_CONTENT_ACTIVITY_TYPE = "yoto_Player";

const URL_MY_PODCASTS = "/my-cards/podcasts";

const YOTO_MEDIA_URL_PREFIX = "yoto:#";

const PodcastIcon = (props) => {
  const { iconUrl, i18n, readOnly, handleIconDialogOpen } = props;
  const IconImage = () => {
    return (
      <img
        className="trackIcon"
        src={getIconUrl(iconUrl)}
        alt={i18n.t("myo_podcast_choose_icon", "Choose a display icon")}
      />
    );
  };

  return (
    <div
      onClick={() => readOnly || handleIconDialogOpen()}
      style={{
        cursor: readOnly ? "default" : "pointer",
        borderColor: readOnly ? "#00000030" : "#1374E0",
        borderStyle: "solid",
        padding: "5px",
        borderRadius: "8px",
        marginRight: "10px",
        height: "52px",
      }}
    >
      <IconImage />
    </div>
  );
};

PodcastIcon.propTypes = {
  iconUrl: PropTypes.string,
  i18n: PropTypes.object,
  readOnly: PropTypes.bool,
  handleIconDialogOpen: PropTypes.func,
};

class EditPodcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: null,
      readyToSave: false,
      working: false,
      requestCardId: props.params.cardId,
      rssUrl: "",
      fetchingPodcast: false,
      iconDialogOpen: false,
    };
    this.i18n = this.props.i18n;
  }

  async componentDidMount() {
    if (!isLoggedIn()) {
      login(this.props.location.pathname);
    }
    const { cardId } = this.props.params;
    if (cardId) {
      getCard(cardId).then((card) => {
        //console.log(JSON.stringify(card));
        if (!card.metadata) card.metadata = {};
        const rssUrl =
          (card.content &&
            card.content.editSettings &&
            card.content.editSettings.rssUrl) ||
          (card.metadata && card.metadata.feedUrl);
        if (!card.content.editSettings.podcastTrackDisplay)
          card.content.editSettings.podcastTrackDisplay = {};
        if (!card.content.editSettings.podcastTrackDisplay.icon16x16)
          card.content.editSettings.podcastTrackDisplay.icon16x16 = `${YOTO_MEDIA_URL_PREFIX}${DEFAULT_ICON_MEDIA_ID}`;
        this.setState({ rssUrl });
        this.refreshCard(card);
      });
    } else {
      this.setState({ card: this.createNewCard() });
    }
  }

  handleIconDialogOpen() {
    this.setState({ iconDialogOpen: true });
  }

  handleIconDialogCancel() {
    this.setState({ iconDialogOpen: false });
  }

  changeChapterIcon(iconMediaId) {
    //console.log(chapterKey);
    this.setState({ iconDialogOpen: false });
    const { card } = this.state;
    const { chapters } = card.content;
    if (!chapters) return;
    for (const c of chapters) {
      const track = c.tracks ? c.tracks[0] : null;
      if (!c.display) c.display = {};
      c.display.icon16x16 = `yoto:#${iconMediaId}`;
      if (!track) continue;
      if (!track.display) track.display = {};
      track.display.icon16x16 = `yoto:#${iconMediaId}`;
    }
    if (!card.content.editSettings.podcastTrackDisplay)
      card.content.editSettings.podcastTrackDisplay = {};
    card.content.editSettings.podcastTrackDisplay.icon16x16 = `${YOTO_MEDIA_URL_PREFIX}${iconMediaId}`;
    this.refreshCard(card);
  }

  isReadyToSave(card) {
    return (
      card.content.chapters.length > 0 &&
      card.metadata.feedUrl.length > 0 &&
      this.state.rssUrl.length > 0
    );
  }

  refreshCard(card) {
    let cardDuration = 0;
    let cardFileSize = 0;
    let cardHasStreams = false;
    for (let c of card.content.chapters) {
      cardDuration += c.duration;
      cardFileSize += c.fileSize;
      cardHasStreams = cardHasStreams || c.hasStreams;
    }
    if (!card.metadata) card.metadata = {};
    if (!card.metadata.media) card.metadata.media = {};
    card.metadata.category = CARD_CATEGORY_PODCAST;
    card.metadata.media.duration = cardDuration;
    card.metadata.media.fileSize = cardFileSize;
    card.metadata.media.hasStreams = cardHasStreams;
    card.metadata.feedUrl = this.state.rssUrl;
    card.content.editSettings.rssUrl = this.state.rssUrl;
    card.content.editSettings.podcastType = RSS_PODCAST_TYPE;
    this.setState({ card });
    this.setState({ readyToSave: this.isReadyToSave(card) });
  }

  createNewCard() {
    return {
      content: {
        activity: DEFAULT_CONTENT_ACTIVITY_TYPE,
        chapters: [],
        restricted: true,
        config: { onlineOnly: true },
        editSettings: {
          podcastTrackDisplay: {
            icon16x16: `${YOTO_MEDIA_URL_PREFIX}${DEFAULT_ICON_MEDIA_ID}`,
          },
          podcastType: RSS_PODCAST_TYPE,
        },
        version: "1",
      },
      metadata: {
        category: CARD_CATEGORY_PODCAST,
      },
    };
  }

  updateTitle(title) {
    const { card } = this.state;
    card.title = title;
    this.refreshCard(card);
  }

  validateUrl(url) {
    try {
      return new URL(url);
    } catch (_) {
      return false;
    }
  }

  async checkTitleAndSave() {
    const { card } = this.state;
    if (card.title) return this.save();
    swal({
      buttons: {
        cancel: this.i18n.t("common_cancel_button", "Cancel"),
        confirm: {
          className: "swal-ok-button",
          text: this.i18n.t("common_save_button", "Save"),
        },
      },
      content: (
        <div>
          <h3>
            <Trans i18nKey="myo_podcast_title_prompt">
              Give your podcast a name:
            </Trans>
          </h3>
          <input
            type="text"
            style={{ width: "90%", border: "1px #" }}
            placeholder={this.i18n.t(
              "myo_podcast_title_example",
              "e.g. The Best Kids Podcast",
            )}
            onChange={(event) => this.updateTitle(event.target.value)}
          />
        </div>
      ),
    }).then((value) => {
      if (!value) return;
      return this.save();
    });
  }

  async save() {
    if (this.state.working) return;
    this.setState({ working: true });
    const { card } = this.state;
    try {
      await this.uploadCardIcons(card);
      const publishedCard = await updateCard(card);
      card.cardId = publishedCard.cardId;
      this.refreshCard(card);
      browserHistory.push(URL_MY_PODCASTS);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ working: false });
    }
  }

  async uploadCardIcons(card) {
    const icons = new Set();
    if (
      card.content.editSettings.podcastTrackDisplay &&
      card.content.editSettings.podcastTrackDisplay.icon16x16
    )
      icons.add(card.content.editSettings.podcastTrackDisplay.icon16x16);
    for (const chapter of card.content.chapters) {
      if (chapter.display && chapter.display.icon16x16)
        icons.add(chapter.display.icon16x16);
      for (const track of chapter.tracks) {
        if (track.display && track.display.icon16x16)
          icons.add(track.display.icon16x16);
      }
    }
    await addIconsToUser(
      Array.from(icons)
        .filter((i) => i.startsWith(YOTO_MEDIA_URL_PREFIX))
        .map((i) => ({ mediaId: i.split(YOTO_MEDIA_URL_PREFIX)[1] })),
    );
  }

  cleanUrl(url) {
    const cleanedUrl = new URL(url);
    cleanedUrl.protocol = cleanedUrl.protocol.toLowerCase();
    return cleanedUrl.toString();
  }

  updateCoverImage(cardMetadata, imageUrl) {
    if (!cardMetadata.cover) cardMetadata.cover = {};
    if (imageUrl) {
      cardMetadata.cover.imageL = imageUrl;
    }
  }

  async loadFromPodcastApi() {
    const i18n = this.i18n;
    const { card, rssUrl } = this.state;

    if (!rssUrl) {
      return;
    }

    try {
      this.setState({ fetchingPodcast: true });
      const rssUrl = this.cleanUrl(this.state.rssUrl);
      this.setState({ rssUrl: rssUrl });

      const rssResult = await getRSSPodcast(rssUrl);
      if (!rssResult) {
        throw new Error(
          i18n.t(
            "myo_error_unsupported_rss_url",
            "Unfortunately we couldn't find a supported RSS podcast at this URL.",
            "error",
          ),
        );
      }
      if (rssResult.metadata.imageUrl) {
        this.updateCoverImage(
          rssResult.metadata,
          await this.loadCoverImage(rssResult.metadata.imageUrl),
        );
      }

      _.merge(card, rssResult);
      this.refreshCard(card);
      this.setState({ fetchingPodcast: false });
    } catch (err) {
      console.log(err);
      this.setState({ fetchingPodcast: false });
      swal(
        null,
        i18n.t(
          "myo_error_unsupported_rss_url",
          "Unfortunately we couldn't find a supported RSS podcast at this URL.",
          "error",
        ),
      );
    }
  }

  async loadCoverImage(url) {
    const uploadResponse = await uploadCoverImageFromUrl(
      url,
      CARD_CATEGORY_PODCAST,
    );
    if (!uploadResponse) {
      return null;
    }
    return uploadResponse.mediaUrl;
  }

  formatDuration(seconds) {
    if (!seconds || seconds <= 0) return "";
    const DURATION_UNIT_SECONDS = "seconds";
    const duration = moment.duration(seconds, DURATION_UNIT_SECONDS);
    return `${Math.floor(duration.asMinutes())}:${duration
      .seconds()
      .toString()
      .padStart(2, "0")}`;
  }

  render() {
    const { card, readyToSave, working, fetchingPodcast, requestCardId } =
      this.state;
    const i18n = this.props.i18n;
    const readOnly = card && card.readOnly === true;

    const podcastHelpUrl = i18n.t(
      "myo_podcast_help_url",
      "https://support.yotoplay.com/en-US/make-a-card-from-podcasts-that-are-not-on-the-yoto-app-46986",
    );

    return (
      <ErrorBoundary>
        <AutoLogout />
        <PageHeader
          title={
            card
              ? card.title ||
                i18n.t("myo_edit_podcast_title_create", "Add podcast")
              : i18n.t("common_loading", "Loading...")
          }
          i18n={i18n}
          selected={MENU_TAB_PODCASTS}
        />
        {card ? (
          <span>
            <div className={`btn-foot-bar ${!readyToSave ? "disabled" : ""}`}>
              <div className="container content" style={{ padding: 0 }}>
                <div className="row">
                  {readOnly ? (
                    <div
                      className="col-lg-12 col-12"
                      style={{ textAlign: "center", padding: "12px" }}
                    >
                      <SecondaryButton
                        variant="contained"
                        style={{ width: "100%", maxWidth: "300px" }}
                        onClick={browserHistory.goBack}
                      >
                        <Trans i18nKey="common_close_button">Close</Trans>
                      </SecondaryButton>
                    </div>
                  ) : (
                    <>
                      <div
                        className="col-lg-6 col-6"
                        style={{ textAlign: "right", padding: "12px" }}
                      >
                        <SecondaryButton
                          style={{ maxWidth: "300px" }}
                          onClick={browserHistory.goBack}
                        >
                          <Trans i18nKey="common_cancel_button">Cancel</Trans>
                        </SecondaryButton>
                      </div>
                      <div
                        className="col-lg-6 col-6"
                        style={{ textAlign: "left", padding: "12px" }}
                      >
                        <PrimaryButton
                          style={{ maxWidth: "300px" }}
                          disabled={
                            !readyToSave ||
                            working ||
                            fetchingPodcast ||
                            readOnly
                          }
                          onClick={() => this.checkTitleAndSave()}
                        >
                          {i18n.t("myo_podcast_save_button", "Save")}
                        </PrimaryButton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="container content sp">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <h1 className="myo-title">
                    {!requestCardId
                      ? i18n.t("myo_edit_podcast_title_create", "Add podcast")
                      : readOnly
                      ? i18n.t(
                          "myo_edit_podcast_title_readonly",
                          "Shared podcast",
                        )
                      : i18n.t(
                          "myo_edit_podcast_title_edit_existing",
                          "Update podcast",
                        )}
                  </h1>
                  <p className="myo-subheading">
                    {readOnly ? (
                      <Trans i18nKey="myo_edit_podcast_intro_readonly">
                        This podcast has public sharing enabled and cannot be
                        edited anymore.
                      </Trans>
                    ) : (
                      <Trans i18nKey="myo_edit_podcast_intro">
                        Add a podcast to your library by copying and pasting its
                        RSS link.
                      </Trans>
                    )}
                  </p>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-lg-12 col-sm-12"
                  style={{ display: "flex" }}
                >
                  <h5 className="field-label">
                    <Trans i18nKey="myo_podcast_icon_and_rss_link_title">
                      Podcast icon and RSS link
                    </Trans>
                  </h5>
                </div>
                <div
                  className="col-lg-9 col-sm-12"
                  style={{ display: "flex", margin: "0 0 20px 0" }}
                >
                  <PodcastIcon
                    i18n={i18n}
                    iconUrl={
                      card.content.editSettings.podcastTrackDisplay.icon16x16
                    }
                    readOnly={readOnly}
                    handleIconDialogOpen={() => this.handleIconDialogOpen()}
                  />
                  <TextField
                    fullWidth
                    id="standard-error-helper-text"
                    placeholder={i18n.t(
                      "myo_podcast_add_rss_url_placeholder",
                      "Paste your Podcast RSS link here",
                    )}
                    variant="outlined"
                    disabled={readOnly}
                    value={this.state.rssUrl}
                    label={
                      !this.state.rssUrl || this.validateUrl(this.state.rssUrl)
                        ? ""
                        : i18n.t(
                            "myo_podcast_add_rss_invalid_url",
                            "Make sure URL starts with http:// or https://",
                          )
                    }
                    onChange={(event) =>
                      this.setState({ rssUrl: event.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <a
                            style={{ cursor: "pointer" }}
                            target="help"
                            href={podcastHelpUrl}
                          >
                            <HelpIcon />
                          </a>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  className="col-lg-3 col-sm-12"
                  style={{ textAlign: "center", margin: "0 0 30px 0" }}
                >
                  <SecondaryButton
                    style={{ marginTop: "-2px" }}
                    onClick={() => this.loadFromPodcastApi()}
                    disabled={
                      !this.state.rssUrl ||
                      !!working ||
                      !!fetchingPodcast ||
                      card.readOnly
                    }
                  >
                    {!!working || !!fetchingPodcast ? (
                      <Trans i18nKey="myo_podcast_load_rss_button_loading">
                        Retrieving...
                      </Trans>
                    ) : card.content.chapters &&
                      card.content.chapters.length > 0 ? (
                      <Trans i18nKey="myo_podcast_load_rss_button_refresh">
                        Refresh podcast details
                      </Trans>
                    ) : (
                      <Trans i18nKey="myo_podcast_load_rss_button">
                        Get podcast details
                      </Trans>
                    )}
                  </SecondaryButton>
                </div>
              </div>

              {!card.metadata.description &&
              !(card.content.chapters && card.content.chapters.length > 0) ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-lg-3 col-sm-12" style={{}}>
                    <div className="content">
                      <div className="row">
                        <div
                          className="col-lg-8 col-sm-12"
                          style={{ marginBottom: "24px" }}
                        >
                          <CoverImageDetail
                            image={
                              card.metadata.cover
                                ? card.metadata.cover.imageL
                                : null
                            }
                            cardCategory={CARD_CATEGORY_PODCAST}
                            onChange={(imageUrl) => {
                              this.updateCoverImage(card.metadata, imageUrl);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-sm-12">
                    <div className="content">
                      <div className="row">
                        <div
                          className="col-lg-12 col-sm-12"
                          style={{
                            textAlign: "justify",
                            paddingBottom: "20px",
                          }}
                        >
                          <h3>{card.title}</h3>
                          <h4>{card.metadata.author}</h4>
                          <div>{card.metadata.description}</div>
                        </div>
                      </div>
                      {!card.content.chapters
                        ? ""
                        : card.content.chapters.map((c, i) => (
                            <div
                              key={i}
                              className="row"
                              style={{
                                padding: "12px 0",
                                margin: "0 4px",
                                borderBottomStyle: "dotted",
                                borderBottomColor: "#ccc",
                              }}
                            >
                              <div
                                className="col-lg-3 col-sm-2 col-2"
                                style={{
                                  whiteSpace: "nowrap",
                                  textAlign: "center",
                                }}
                              >
                                {c.overlayLabel}
                              </div>
                              <div className="col-lg-7 col-sm-8 col-8">
                                {c.title}
                              </div>
                              <div className="col-lg-2 col-sm-2 col-2">
                                {this.formatDuration(c.duration)}
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  {!requestCardId ? (
                    ""
                  ) : (
                    <>
                      <div className="col-lg-3 col-sm-12"></div>
                      <div
                        style={{ textAlign: "right", padding: "16px" }}
                        className="col-lg-8 col-12"
                      >
                        <div className="divider-row"></div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <DeleteCardButton
                              cardId={card.cardId}
                              cardTitle={card.title}
                              cardType={card.metadata.category}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className="cardLibSpacer" />
            </div>
          </span>
        ) : (
          ""
        )}

        <IconSelector
          open={this.state.iconDialogOpen}
          onClose={() => this.handleIconDialogCancel()}
          onChange={(_ignored, mediaId) => this.changeChapterIcon(mediaId)}
        />
      </ErrorBoundary>
    );
  }
}

EditPodcast.propTypes = propTypes;

export default withTranslation()(EditPodcast);

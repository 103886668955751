import axios from 'axios';
import { getAccessToken } from './AuthService';
import STAGES from './stages.js';

const BASE_URL =
    STAGES.API_DOMAINS[STAGES.CLIENT_URLS[window.location.hostname]];
const MEDIA_DOMAIN =
    STAGES.MEDIA_DOMAINS[STAGES.CLIENT_URLS[window.location.hostname]];
const DEVICE_PREFIXES =
    STAGES.DEVICE_PREFIXES[STAGES.CLIENT_URLS[window.location.hostname]];

const OFFICIAL_CARD_RESOLVE_DOMAIN = 'yoto.io';
const YOTO_VALID_DOMAINS = [
    new URL(BASE_URL).hostname,
    OFFICIAL_CARD_RESOLVE_DOMAIN
];

/* eslint-disable i18next/no-literal-string */
const DEVICE_V1 = 'v1';
const DEVICE_V2 = 'v2';
const CONTENT_TYPE_JSON = 'application/json';
const YOTO_MEDIA_URL_PREFIX = 'yoto:#';
/* eslint-enable i18next/no-literal-string */

function isBetaWebsite() {
    return STAGES.CLIENT_URLS[window.location.hostname] !== 'prod';
}

function getDeviceVersion(deviceId) {
    if (!deviceId) return null;
    if (
        deviceId.startsWith(DEVICE_PREFIXES.v2) &&
        [20, 22].includes(deviceId.length - DEVICE_PREFIXES.v2.length)
    )
        return DEVICE_V2;
    if (
        deviceId.startsWith(DEVICE_PREFIXES.v1) &&
        deviceId.length === 22 + DEVICE_PREFIXES.v1.length
    )
        return DEVICE_V1;
    throw new Error(`Unknown device type for deviceId ${deviceId}`);
}

function getDeviceStatus(deviceId) {
    if (getDeviceVersion(deviceId) === DEVICE_V1) {
        return getDeviceStatusV1(deviceId);
    } else {
        return getDeviceStatusV2(deviceId);
    }
}

function getDeviceStatusV1(deviceId) {
    const url = `${BASE_URL}/device/${deviceId}/details`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.device);
}

function getDeviceStatusV2(deviceId) {
    const url = `${BASE_URL}/device-v2/${deviceId}/details`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.device);
}

function getDeviceConfigV1(deviceId) {
    const url = `${BASE_URL}/config/device/${deviceId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.device);
}

function updateDeviceConfigV1(deviceId, deviceConfig) {
    const url = `${BASE_URL}/config/device/${deviceId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .post(url, { deviceId, ...deviceConfig }, config)
        .then((response) => response.data.device);
}

function getDeviceConfigV2(deviceId) {
    const url = `${BASE_URL}/device-v2/${deviceId}/config`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.device);
}

function updateDeviceConfigV2(deviceId, deviceConfig) {
    const url = `${BASE_URL}/device-v2/${deviceId}/config`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .put(url, { deviceId, ...deviceConfig }, config)
        .then((response) => response.data.device);
}

function updateDeviceReleaseChannel(deviceId, releaseChannelId) {
    const url = `${BASE_URL}/device-v2/${deviceId}/releaseChannel?releaseChannelId=${releaseChannelId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .put(url, { releaseChannelId }, config)
        .then((response) => response.data);
}

function getUserCards() {
    const url = `${BASE_URL}/card/mine`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data);
}

function sendDeviceCommand(deviceId, command, payload) {
    const url = `${BASE_URL}/device-v2/${deviceId}/command/${command}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .post(url, payload, config)
        .then((response) => response.data.data);
}

function getCard(cardId) {
    const url = `${BASE_URL}/content/${cardId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.card);
}

function resolveCard(url) {
    const config = { headers: { Accept: CONTENT_TYPE_JSON } };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.card);
}

function validateStreamUrl(streamUrl) {
    const url = `${BASE_URL}/media/url/format`;
    const config = {
        params: { streamUrl },
        headers: {}
    };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.stream);
}

function getUploadUrlForTranscode(sha256, filename) {
    const url = `${BASE_URL}/media/transcode/audio/uploadUrl?sha256=${sha256}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    if (filename) config.params = { filename };
    return axios.get(url, config).then((response) => response.data.upload);
}

function getTranscodedUpload(uploadId) {
    const url = `${BASE_URL}/media/upload/${uploadId}/transcoded?loudnorm=false`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.transcode);
}

function addIconsToUser(icons) {
    const url = `${BASE_URL}/media/user/icons`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.post(url, { icons }, config).then((response) => response.data);
}

async function updateCard(card) {
    const url = `${BASE_URL}/content`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    const response = await axios.post(url, card, config);
    return response.data.card;
}

function getDeviceQueueCards(deviceId) {
    const url = `${BASE_URL}/sync/queue/cards?deviceId=${deviceId}&refresh=true&excludeSystemCards=true`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then((response) => response.data.queue);
}

function removeDeviceFromFamily(deviceId) {
    const url = `${BASE_URL}/user/family/mine/device/${deviceId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.delete(url, config).then((response) => response.data);
}

function getUserDisplayIcons(iconUserId = 'me') {
    let url = `${BASE_URL}/media/displayIcons/user/${iconUserId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .get(url, config)
        .then((response) => response.data.displayIcons);
}

function createUserDisplayIcon(displayIcon, iconUserId = 'me') {
    const url = `${BASE_URL}/media/displayIcons/user/${iconUserId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .post(url, { displayIcon }, config)
        .then((response) => response.data.displayIcon);
}

function updateDisplayIcon(displayIconId, icon) {
    let url = `${BASE_URL}/media/displayIcon/${displayIconId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .put(url, { displayIcon: icon }, config)
        .then((response) => response.data.displayIcon);
}

function deleteDisplayIcon(displayIconId) {
    let url = `${BASE_URL}/media/displayIcon/${displayIconId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .delete(url, config)
        .then((response) => response.data.displayIcon);
}

async function uploadIcon(iconFile, iconUserId = 'me') {
    const data = new FormData();
    /* eslint-disable i18next/no-literal-string */
    data.append('image', iconFile);
    /* eslint-enable i18next/no-literal-string */
    const url = `${BASE_URL}/media/displayIcons/user/${iconUserId}/upload`;
    const config = { headers: { 'Content-Type': iconFile.type } };
    config.headers.Authorization = generateAuthorizationHeader(url);

    return axios.post(url, iconFile, config);
}

async function uploadCoverImage(
    imageFile,
    coverType = 'default',
    imageUserId = 'me'
) {
    const data = new FormData();
    /* eslint-disable i18next/no-literal-string */
    data.append('image', imageFile);
    /* eslint-enable i18next/no-literal-string */
    const url = `${BASE_URL}/media/coverImage/user/${imageUserId}/upload?coverType=${coverType}`;
    const config = { headers: { 'Content-Type': imageFile.type } };
    config.headers.Authorization = generateAuthorizationHeader(url);

    return axios
        .post(url, imageFile, config)
        .then((response) => response.data.coverImage)
        .catch((reason) => console.log(reason));
}

async function uploadCoverImageFromUrl(
    imageUrl,
    coverType = 'default',
    imageUserId = 'me'
) {
    const url = `${BASE_URL}/media/coverImage/user/${imageUserId}/upload?imageUrl=${imageUrl}&coverType=${coverType}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios
        .post(url, null, config)
        .then((response) => {
            console.log(response);
            return response.data.coverImage;
        })
        .catch((error) => {
            // eslint-disable-next-line i18next/no-literal-string
            console.warn('unable to upload cover image from imageUrl', error);
            return null;
        });
}

function getIconUrl(mediaId) {
    if (!mediaId) return null;
    if (mediaId.startsWith('http://') || mediaId.startsWith('https://'))
        return mediaId;
    if (mediaId.startsWith(YOTO_MEDIA_URL_PREFIX))
        mediaId = mediaId.split(YOTO_MEDIA_URL_PREFIX)[1];
    return `${MEDIA_DOMAIN}/icons/${mediaId}`;
}

function generateAuthorizationHeader(url) {
    const { hostname } = new URL(url);
    if (!YOTO_VALID_DOMAINS.includes(hostname)) return undefined;
    const token = getAccessToken();
    return token ? `Bearer ${getAccessToken()}` : undefined;
}

function postBrithdayShoutout(formData) {
    return axios.post(`${BASE_URL}/birthdays/shoutout`, formData);
}
function getBrithdayAvailability() {
    return axios.get(`${BASE_URL}/birthdays/availability`);
}

async function getRSSPodcast(feedurl) {
    const url = `${BASE_URL}/podcasts/convert?force=true&feedurl=${encodeURIComponent(
        feedurl
    )}`;
    const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${getAccessToken()}` }
    });
    return response.data.podcast;
}

async function deleteCard(cardId) {
    const url = `${BASE_URL}/content/${cardId}`;
    return axios
        .delete(url, {
            headers: { Authorization: `Bearer ${getAccessToken()}` }
        })
        .then((response) => response.data);
}

export {
    postBrithdayShoutout,
    getBrithdayAvailability,
    isBetaWebsite,
    getDeviceVersion,
    getDeviceStatus,
    sendDeviceCommand,
    updateCard,
    getCard,
    validateStreamUrl,
    //    getUploadUrl,
    getUploadUrlForTranscode,
    getTranscodedUpload,
    addIconsToUser,
    resolveCard,
    getDeviceConfigV1,
    getDeviceConfigV2,
    updateDeviceConfigV1,
    updateDeviceConfigV2,
    updateDeviceReleaseChannel,
    getUserCards,
    getDeviceQueueCards,
    removeDeviceFromFamily,
    getUserDisplayIcons,
    createUserDisplayIcon,
    updateDisplayIcon,
    deleteDisplayIcon,
    uploadIcon,
    uploadCoverImage,
    uploadCoverImageFromUrl,
    getIconUrl,
    getRSSPodcast,
    deleteCard
};

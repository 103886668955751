/* eslint-disable i18next/no-literal-string */
import React, { Component } from "react";
import PropTypes from "prop-types";
import semver from "semver";
import { isLoggedIn, login } from "../utils/AuthService";
import "../index.css";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";

import ReplayIcon from "@material-ui/icons/Replay";
import Button from "@material-ui/core/Button";

import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Switch from "@material-ui/core/Switch/Switch";

import { Trans, withTranslation } from "react-i18next";

//import i18next from 'i18next';

const api = require("../utils/yoto-api");

const GENERAL_RELEASE_CHANNELS = ["general", "prerelease"];
const INTERNAL_RELEASE_CHANNELS = [
  "alpha",
  "development",
  "latest-master",
  "custom-master",
];

const nonBetaDefaultConfig = {
  repeatAll: false,
};

class DeviceBetaConfig extends Component {
  constructor() {
    super();

    this.state = {
      deviceConfig: null,
      updatedConfig: { config: {} },
      message: null,
      restartRequired: false,
      openDialog: null,
      refreshingStatus: false,
      loadingMessage: (
        <Trans i18nKey="beta_settings_loading">
          Loading Player Beta Settings...
        </Trans>
      ),
    };
  }

  componentDidMount() {
    if (!isLoggedIn()) {
      login(this.props.location.pathname);
    }
    this.loadDeviceConfig();
  }

  handleOpenBrowser(url) {
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.openBrowser
    ) {
      window.webkit.messageHandlers.openBrowser.postMessage(url);
    } else if (window.mobile && window.mobile.openBrowser) {
      window.mobile.openBrowser(url);
    } else if (window.openBrowser) {
      window.openBrowser(url);
    } else {
      const TARGET = "_blank";
      window.open(url, TARGET);
    }
  }

  async loadDeviceConfig() {
    this.deviceId = this.props.params.deviceId;
    this.setState({ refreshingStatus: true });
    this.setState({
      loadingMessage: (
        <Trans i18nKey="beta_settings_loading">
          Loading Player Beta Settings...
        </Trans>
      ),
    });
    if (!isLoggedIn()) return;
    try {
      const dConfig = await api.getDeviceConfigV2(this.deviceId);
      this.setState({
        deviceConfig: dConfig,
      });
    } catch (err) {
      this.setState({
        loadingMessage: (
          <Trans i18nKey="beta_settings_loading_error">
            Sorry, we are unable to load Beta settings for this Yoto Player,
            please tap the back button and try again
          </Trans>
        ),
      });
    } finally {
      this.setState({ refreshingStatus: false });
    }
  }

  updateConfigEvent(event) {
    this.updateConfig(event.target.name, event.target.value);
  }

  updateConfig(configKey, value) {
    const { deviceConfig } = this.state;
    console.log(configKey);
    console.log(value);
    console.log(`${deviceConfig.config[configKey]}->${value}`);
    if (deviceConfig.config[configKey] !== value) {
      deviceConfig.config[configKey] = value;
      const updatedConfig = {};
      updatedConfig[configKey] = value;
      this.applyConfig(updatedConfig);
      this.setState({ deviceConfig });
    }
  }

  applyConfig(config) {
    api
      .updateDeviceConfigV2(this.deviceId, { config })
      .then(() => {
        if (this.savedMessageTimeout) clearTimeout(this.savedMessageTimeout);
        let timeoutMs = 3000;
        let additionalMessage = null;
        if (this.state.restartRequired) {
          additionalMessage = (
            <Trans key="msg2" i18nKey="settings_saved_restart_required">
              Yoto Player requires restarting to update.
            </Trans>
          );
          this.setState({ restartRequired: false });
        }
        this.setState({
          message: [
            <Trans key="msg1" i18nKey="settings_saved">
              Settings Saved.
            </Trans>,
            additionalMessage,
          ],
        });
        this.savedMessageTimeout = setTimeout(() => {
          this.setState({ message: null });
        }, timeoutMs);
      })
      .catch((err) => {
        console.log(`error saving config ${JSON.stringify(err.response)}`);
      });
  }

  toggleRepeatAll() {
    const { deviceConfig } = this.state;
    deviceConfig.config.repeatAll = !deviceConfig.config.repeatAll;
    this.applyConfig({ repeatAll: deviceConfig.config.repeatAll });
    this.setState({ deviceConfig });
  }

  async toggleBeta(on) {
    const { deviceConfig } = this.state;
    deviceConfig.releaseChannelId = on ? "beta" : "general";
    await api.updateDeviceReleaseChannel(
      this.deviceId,
      deviceConfig.releaseChannelId,
    );
    if (this.savedMessageTimeout) clearTimeout(this.savedMessageTimeout);
    let timeoutMs = 4000;
    this.setState({
      message: [
        <Trans key="msg1" i18nKey="settings_saved">
          Settings Saved.
        </Trans>,
        null,
      ],
    });
    this.savedMessageTimeout = setTimeout(() => {
      this.setState({ message: null });
    }, timeoutMs);
    if (!on) {
      //revert all settings to default as they might be working in GR as well (but hidden/not stable)
      deviceConfig.config = { ...deviceConfig.config, ...nonBetaDefaultConfig };
      this.setState({ deviceConfig });
      await api.updateDeviceConfigV2(this.deviceId, {
        config: nonBetaDefaultConfig,
      });
    }
    await this.loadDeviceConfig();
  }

  render() {
    const { deviceConfig, loadingMessage } = this.state;
    const isInternal =
      deviceConfig &&
      (INTERNAL_RELEASE_CHANNELS.includes(deviceConfig.releaseChannelId) ||
        deviceConfig.releaseChannelId.startsWith("testing-"));
    const isBeta = deviceConfig && deviceConfig.releaseChannelId === "beta";
    const isGeneralRelease =
      deviceConfig &&
      GENERAL_RELEASE_CHANNELS.includes(deviceConfig.releaseChannelId);

    const hasOct2020Update =
      deviceConfig &&
      semver.satisfies(semver.coerce(deviceConfig.fwVersion), ">=2.4.2606");
    const updateAvailable =
      deviceConfig &&
      deviceConfig.releaseChannelVersion &&
      deviceConfig.releaseChannelVersion !== deviceConfig.fwVersion;

    return (
      <div>
        <div>
          {this.state.message ? (
            <div className="message">
              <div>{this.state.message[0]}</div>
              {this.state.message[1] ? (
                <div
                  style={{ fontSize: "0.7em", color: "#003C1F", opacity: "1" }}
                >
                  {this.state.message[1]}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {!deviceConfig ? (
          <div style={{ width: "100%", textAlign: "center", padding: "2em" }}>
            {loadingMessage}
          </div>
        ) : (
          <div style={{ maxWidth: "550px" }}>
            {deviceConfig ? (
              <div>
                {
                  <>
                    <List className="config-list">
                      <ListSubheader
                        className="config-subheading"
                        disableSticky
                      >
                        <Trans i18nKey="beta_settings_title">
                          Yoto Beta Labs
                        </Trans>
                      </ListSubheader>
                      <ListItem disableGutters={true} className="config-items">
                        <ListItemText
                          primary={
                            <Trans i18nKey="beta_settings_intro">
                              Beta version of Yoto OS allows you to preview new
                              features ahead of general release and help us road
                              test them.
                            </Trans>
                          }
                        />
                      </ListItem>
                      {!isBeta ? (
                        ""
                      ) : (
                        <ListItem
                          disableGutters={true}
                          className="config-items"
                        >
                          <ListItemText>
                            <b>This Player is signed up for Yoto OS Beta.</b>
                          </ListItemText>
                        </ListItem>
                      )}
                      <ListItem disableGutters={true} className="config-items">
                        <ListItemText
                          className="config-key"
                          primary={
                            <Trans i18nKey="settings_yoto_os_version">
                              Yoto OS Version
                            </Trans>
                          }
                        />
                        <ListItemSecondaryAction className="config-value">
                          {deviceConfig.fwVersion.split("-")[0]}
                        </ListItemSecondaryAction>
                      </ListItem>
                      {!updateAvailable ? (
                        ""
                      ) : (
                        <ListItem
                          disableGutters={true}
                          className="config-items"
                        >
                          <ListItemText>
                            <i>
                              Update to Yoto OS{" "}
                              {deviceConfig.releaseChannelVersion.split("-")[0]}{" "}
                              pending. Player will automatically update when
                              online and idle.
                            </i>
                          </ListItemText>
                        </ListItem>
                      )}
                      {!isBeta ? (
                        ""
                      ) : (
                        <ListItem
                          disableGutters={true}
                          className="config-items"
                        >
                          <ListItemText>
                            <p>
                              See{" "}
                              <button
                                style={{
                                  border: "none",
                                  color: "#3488E5",
                                  background: "none",
                                  padding: 0,
                                }}
                                onClick={() => {
                                  this.handleOpenBrowser(
                                    "https://support.yotoplay.com/en-US/yoto-beta-program-11567",
                                  );
                                }}
                              >
                                latest Beta notes
                              </button>{" "}
                              and email beta@yotoplay.com with any feedback or
                              to report issues.
                            </p>
                          </ListItemText>
                        </ListItem>
                      )}
                      {isGeneralRelease ? (
                        <ListItem disableGutters={true}>
                          <ListItemText className="config-value">
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                this.toggleBeta(true);
                              }}
                            >
                              Join Beta Program
                            </Button>
                          </ListItemText>
                        </ListItem>
                      ) : isBeta ? (
                        <ListItem
                          disableGutters={true}
                          className="config-items"
                        >
                          <ListItemText>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                this.toggleBeta(false);
                              }}
                              size="small"
                            >
                              Leave Beta Program
                            </Button>
                          </ListItemText>
                        </ListItem>
                      ) : isInternal ? (
                        <ListItem disableGutters={true}>
                          <ListItemText
                            primary={
                              "This Player is currently registered for internal testing."
                            }
                          />
                        </ListItem>
                      ) : (
                        <ListItem disableGutters={true}>
                          <ListItemText>
                            Beta is currently unavailable for this player.
                          </ListItemText>
                        </ListItem>
                      )}
                    </List>
                    {!isBeta && !isInternal ? (
                      ""
                    ) : (
                      <List className="config-list">
                        <ListSubheader
                          className="config-subheading"
                          disableSticky
                        >
                          Beta Settings
                        </ListSubheader>

                        {/*!hasSept2020Update ? '' :
                                                <ListItem disableGutters={true} className="config-items">

                                                    <ListItemIcon  className="config-icon">
                                                        <PowerSettingsNewIcon />
                                                    </ListItemIcon>
                                                    <ListItemText  className="config-key"
                                                        secondary={
                                                            deviceConfig.config.shutdownTimeout && deviceConfig.config.shutdownTimeout !== '0' ?
                                                                <Trans i18nKey='settings_auto_poweroff_on'>When off its dock, Yoto Player will automatically switch off when not used for...</Trans> :
                                                                <Trans i18nKey='settings_auto_poweroff_off'>Yoto Player will not auto power-off. (Please note: battery will drain even with display off)</Trans>
                                                        }
                                                    ><Trans i18nKey='settings_auto_poweroff'>Auto Power-Off</Trans></ListItemText>
                                                    <ListItemSecondaryAction className="config-value">
                                                        <Select
                                                            autoWidth={true}
                                                            value={deviceConfig.config.shutdownTimeout}
                                                            onChange={(e)=>this.updateConfigEvent(e)}
                                                            inputProps={{
                                                                name: 'shutdownTimeout',
                                                                id: 'shutdownTimeout',
                                                            }}
                                                        >
                                                            <MenuItem value='0'><Trans i18nKey='settings_auto_poweroff_option_off'>Off</Trans></MenuItem>
                                                            <MenuItem value='900'><Trans i18nKey='common_minutes_short' count={15}>{{ count: 15 }} min</Trans></MenuItem>
                                                            <MenuItem value='1800'><Trans i18nKey='common_minutes_short' count={30}>{{ count: 30 }} min</Trans></MenuItem>
                                                            <MenuItem value='3600'><Trans i18nKey='common_hours_short' count={1}>{{ count: 1 }} hr</Trans></MenuItem>
                                                            <MenuItem value='7200'><Trans i18nKey='common_hours_short' count={2}>{{ count: 2 }} hrs</Trans></MenuItem>
                                                            <MenuItem value='10800'><Trans i18nKey='common_hours_short' count={3}>{{ count: 3 }} hrs</Trans></MenuItem>
                                                        </Select>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            */}

                        {!hasOct2020Update ? (
                          ""
                        ) : (
                          <ListItem
                            disableGutters={true}
                            className="config-items"
                          >
                            <ListItemIcon className="config-icon">
                              <ReplayIcon />
                            </ListItemIcon>
                            <ListItemText
                              className="config-key"
                              style={{ paddingRight: 20 }}
                              primary={<>Play Cards on Repeat</>}
                              secondary={
                                deviceConfig.config.repeatAll ? (
                                  <>
                                    Content will continue to play from beginning
                                    again after last track.
                                  </>
                                ) : (
                                  <>Playback will stop after last track.</>
                                )
                              }
                            />
                            <ListItemSecondaryAction className="config-value">
                              <Switch
                                color="primary"
                                onChange={() => {
                                  this.toggleRepeatAll();
                                }}
                                checked={deviceConfig.config.repeatAll}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                      </List>
                    )}
                  </>
                }
                <div className="cardLibSpacer" />{" "}
              </div>
            ) : (
              <div>no-device-err</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

DeviceBetaConfig.propTypes = {
  location: PropTypes.object,
  params: PropTypes.object,
  i18n: PropTypes.object,
};

export default withTranslation()(DeviceBetaConfig);

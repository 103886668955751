export const CHUNKED_FILE_READER_EVENTS = {
    BEGIN: 'begin',
    PROGRESS: 'progress',
    CHUNK: 'chunk',
    END: 'end'
};

export class ChunkedFileReader {
    constructor(opts = {}) {
        this.maxChunkSize = opts.maxChunkSize || 1024 * 1024;
        this.listeners = {};
    }

    subscribe(eventName, listener, thisObj) {
        if (!eventName || !listener) {
            // eslint-disable-next-line i18next/no-literal-string
            throw new Error('Event name and listener must be provided.');
        }

        this.listeners[eventName] = this.listeners[eventName] || [];
        this.listeners[eventName].push({
            ctx: thisObj,
            fun: listener
        });
    }

    publish(eventName, eventArgs) {
        (this.listeners[eventName] || []).forEach((listener) =>
            listener.fun.call(listener.ctx, eventArgs)
        );
    }

    readChunks(input) {
        let chunkSize = Math.min(this.maxChunkSize, input.size);
        let remainingBytes = input.size;
        const numberOfChunks = Math.ceil(remainingBytes / chunkSize);

        let position = 0;
        let sequence = 1;
        const reader = new FileReader();

        reader.onload = (e) => {
            if (e.target.readyState !== FileReader.DONE) {
                return;
            }

            this.publish(CHUNKED_FILE_READER_EVENTS.PROGRESS, {
                nchunks: numberOfChunks,
                done: sequence,
                done_ratio: sequence / numberOfChunks
            });

            this.publish(CHUNKED_FILE_READER_EVENTS.CHUNK, {
                seq: sequence,
                nchunks: numberOfChunks,
                chunk: e.target.result
            });

            ++sequence;

            position += chunkSize;
            remainingBytes -= chunkSize;
            if (remainingBytes < chunkSize) {
                chunkSize = remainingBytes;
            }

            if (remainingBytes > 0) {
                readNextChunk();
            } else {
                this.publish(CHUNKED_FILE_READER_EVENTS.END, {
                    nchunks: numberOfChunks
                });
            }
        };

        const readNextChunk = () => {
            const blob = input.slice(position, position + chunkSize);
            reader.readAsArrayBuffer(blob);
        };

        this.publish(CHUNKED_FILE_READER_EVENTS.BEGIN, {
            nchunks: numberOfChunks
        });

        readNextChunk();
    }
}

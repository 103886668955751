import React, { Component } from "react";
import {
  getUserCards,
  //isBetaWebsite,
} from "../utils/yoto-api";
import { login, isLoggedIn, logout, isAdmin } from "../utils/AuthService";
import "../index.css";
import { Link } from "react-router";
import CardCover from "./CardCover";
import AutoLogout from "./AutoLogout";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import PageHeader from "./PageHeaderMyoMenu";

const NEW_CARD_LINK = "/card/edit";
const NEW_PODCAST_LINK = "/card/edit-podcast";

const DEFAULT_TAB = "playlists";

const isPodcastCard = (card) => {
  return (
    card &&
    card.content &&
    card.content.editSettings &&
    card.content.editSettings.podcastType === "rss-podcast"
  );
};

class UserCards extends Component {
  constructor() {
    super();
    this.state = { isAdmin: isAdmin(), cards: null, selectedTab: DEFAULT_TAB };
  }

  componentDidMount() {
    if (!isLoggedIn()) {
      login(this.props.location.pathname);
    }
    getUserCards()
      .then((cards) => {
        this.setState({
          cards: cards.cards.sort(function (b, a) {
            return a.createdAt < b.createdAt
              ? -1
              : a.createdAt > b.createdAt
              ? 1
              : 0;
          }),
        });
      })
      .catch((err) => console.log(err));
    this.setState({
      selectedTab:
        (this.props && this.props.params && this.props.params.selectedTab) ||
        DEFAULT_TAB,
    });
  }

  render() {
    const { cards } = this.state;
    const i18n = this.props.i18n;

    const { selectedTab } = this.state;

    const pageTitle =
      selectedTab === "playlists"
        ? i18n.t("myo_listing_playlists_tab_title", "My playlists")
        : i18n.t("myo_listing_podcasts_tab_title", "My podcasts");

    return (
      <>
        <AutoLogout />

        <div>
          <PageHeader
            selected={selectedTab}
            title={pageTitle}
            onTabSelected={(tab) => this.setState({ selectedTab: tab })}
            i18n={i18n}
          />
        </div>
        <span>
          {!cards ? (
            <div style={{ width: "100%", textAlign: "center", padding: "2em" }}>
              <Trans i18nKey="myo_listing_loading">
                Loading your playlists...
              </Trans>
            </div>
          ) : (
            <div
              className="container"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              {this.state.selectedTab === "playlists" ? (
                <div className="col-lg-12">
                  <h1 className="myo-title">
                    <Trans i18nKey="myo_listing_playlists_tab_title">
                      My playlists
                    </Trans>
                  </h1>
                  <p className="myo-subheading">
                    {" "}
                    <Trans i18nKey="myo_listing_playlists_tab_desc">
                      Create playlists here, then link them to Make Your Own
                      cards back in the Yoto App
                    </Trans>
                  </p>
                  <div className="row">
                    <div className="col-6 col-sm-6  col-md-4 col-lg-2 card-div user-card-div">
                      <div className="cardCover">
                        <span style={{ display: "block", fontSize: "1.4em" }}>
                          <Link
                            className="card-listing-add-button-link"
                            style={{ display: "block", textAlign: "center" }}
                            to={NEW_CARD_LINK}
                          >
                            <div className="card-cover-listing-container">
                              <img
                                alt=""
                                className="card-listing-add-button"
                                src="/img/card-cover-list-placeholder-add.png"
                              />
                            </div>
                            <div className="card-listing-add-button-text">
                              <Trans i18nKey="myo_listing_btn_add_playlist">
                                Add Playlist
                              </Trans>
                            </div>
                          </Link>
                        </span>
                      </div>
                    </div>

                    {cards ? (
                      cards
                        .filter((c) => !isPodcastCard(c))
                        .map((card) => (
                          <div
                            key={card.cardId}
                            className="col-6 col-sm-6  col-md-4 col-lg-2 card-div user-card-div"
                          >
                            <div className="cardCover">
                              <Link
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                }}
                                to={`/card/${card.cardId}/edit`}
                              >
                                <CardCover
                                  cardTitle={card.title}
                                  coverImageUrl={
                                    card.content &&
                                    card.content.cover &&
                                    card.content.cover.imageL
                                  }
                                />
                              </Link>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              ) : (
                <div className="col-lg-12">
                  <h1 className="myo-title">
                    {" "}
                    <Trans i18nKey="myo_listing_podcasts_tab_title">
                      My podcasts
                    </Trans>
                  </h1>
                  <p className="myo-subheading">
                    {" "}
                    <Trans i18nKey="myo_listing_podcasts_tab_desc">
                      Use RSS feeds to turn podcasts into Make Your Own Cards.
                    </Trans>
                  </p>
                  <div className="row">
                    <div className="col-6 col-sm-6  col-md-4 col-lg-2 card-div user-card-div">
                      <div className="cardCover">
                        <span style={{ display: "block", fontSize: "1.4em" }}>
                          <Link
                            className="card-listing-add-button-link"
                            style={{ display: "block", textAlign: "center" }}
                            to={NEW_PODCAST_LINK}
                          >
                            <div className="card-cover-listing-container">
                              <img
                                alt=""
                                className="card-listing-add-button"
                                src="/img/card-cover-list-placeholder-podcast-add.png"
                              />
                            </div>
                            <div className="card-listing-add-button-text">
                              <Trans i18nKey="myo_listing_btn_add_podcast">
                                Add Podcast
                              </Trans>
                            </div>
                          </Link>
                        </span>
                      </div>
                    </div>

                    {cards ? (
                      cards
                        .filter((c) => isPodcastCard(c))
                        .map((card) => (
                          <div
                            key={card.cardId}
                            className="col-6 col-sm-6  col-md-4 col-lg-2 card-div user-card-div"
                          >
                            <div className="cardCover">
                              <Link
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                }}
                                to={`/card/${card.cardId}/edit-podcast`}
                              >
                                <CardCover
                                  cardTitle={card.title}
                                  cardCategory={card.metadata.category}
                                  coverImageUrl={
                                    card.content &&
                                    card.content.cover &&
                                    card.content.cover.imageL
                                  }
                                />
                              </Link>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </span>

        <footer
          className="footer"
          style={{ width: "100%", textAlign: "center" }}
        >
          <a
            target="_blank"
            style={{ paddingRight: "1em" }}
            href={i18n.t(
              "myo_help_url",
              "https://support.yotoplay.com/make-your-own-cards",
            )}
            rel="noopener noreferrer"
          >
            <Trans i18nKey="myo_listing_help_link_text">Help</Trans>
          </a>
          |{" "}
          <button className="link-button" onClick={() => logout()}>
            <span className="logout">
              <Trans i18nKey="common_logout">Log out</Trans>
            </span>
          </button>
        </footer>
      </>
    );
  }
}

UserCards.propTypes = {
  location: PropTypes.object,
  i18n: PropTypes.object,
  params: PropTypes.object,
};

export default withTranslation()(UserCards);

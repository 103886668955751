import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { uploadCoverImage } from "../../utils/yoto-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import ErrorIcon from "@material-ui/icons/Error";

const RANDOM_COVER_OBJECTS = [
  "apple",
  "bee",

  "book",
  "cactus",
  "cat-keytar",
  "cherries",
  "cloud",
  "diamond",
  "drum",
  "fish",
  "flower",
  "ghost",

  "ice-cream",
  "lolly",
  "microphone",
  "radio",
  "rocket",
  "skull",
  "star",
  "strawberry",
  "sun",
  "unicorn",
];

const RANDOM_COVER_COLOURS = [
  "blue",
  "grapefruit",
  "green",
  "lilac",
  "mint",
  "orange",
  "red",
  "yellow",
];

const pickRandomCoverUrl = () => {
  const object =
    RANDOM_COVER_OBJECTS[
      Math.floor(Math.random() * RANDOM_COVER_OBJECTS.length)
    ];
  const colour =
    RANDOM_COVER_COLOURS[
      Math.floor(Math.random() * RANDOM_COVER_COLOURS.length)
    ];
  return `https://cdn.yoto.io/myo-cover/${object}_${colour}.gif`;
};

const CoverImageDetail = (props) => {
  const { t } = useTranslation();

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [coverImage, setCoverImage] = useState(props.image);
  const [cardCategory, setCardCategory] = useState(props.cardCategory);

  const missingCoverImage = (cardCategory) => {
    const MISSING_COVER_DEFAULT = "/img/MYO.png";
    const MISSING_COVER_PODCAST = "/img/missing-cover-podcast.png";
    switch (cardCategory) {
      case "podcast":
        return MISSING_COVER_PODCAST;
      default:
        return MISSING_COVER_DEFAULT;
    }
  };

  const handleRandomCoverImage = useCallback(() => {
    setIsUploading(true);
    const imageUrl = pickRandomCoverUrl();
    console.log(imageUrl);
    setCoverImage(imageUrl);
    if (props.onChange) props.onChange(imageUrl);
    setIsUploading(false);
  }, [setCoverImage, setIsUploading]);

  useEffect(() => {
    if (coverImage !== props.image || !coverImage) {
      setCoverImage(props.image);
      if (!coverImage && props.cardCategory !== "podcast")
        handleRandomCoverImage();
    }
    if (cardCategory !== props.cardCategory) {
      setCardCategory(props.cardCategory);
    }
  }, [props, setCoverImage, setCardCategory, handleRandomCoverImage]);

  const uploadCoverImageFile = useCallback(async (file, coverType) => {
    if (/\.(png|gif|jpg|jpeg|svg|tif|tiff)$/i.test(file.name) === false) {
      return;
    }
    return uploadCoverImage(file, coverType);
  }, []);

  const showError = useCallback(
    async (err) => {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      setError(err);
      await delay(3000);
      setError(null);
    },
    [setError],
  );

  const handleCoverImageFileSelect = useCallback(
    async (event) => {
      setIsUploading(true);
      const files = event.target.files; // FileList object
      let error;
      let imageUrl;
      try {
        imageUrl = (await uploadCoverImageFile(files[0], cardCategory))
          .mediaUrl;
      } catch (err) {
        error = err;
      }
      if (error) {
        await showError(error);
      } else {
        console.log(imageUrl);
        setCoverImage(imageUrl);
        if (props.onChange) props.onChange(imageUrl);
      }
      setIsUploading(false);
    },
    [
      setCoverImage,
      uploadCoverImageFile,
      cardCategory,
      setIsUploading,
      showError,
    ],
  );

  return (
    <div
      style={{
        //maxHeight: "80vh",
        //width: "auto",
        textAlign: "center",
      }}
    >
      <div className="content">
        <div
          style={{
            backgroundColor: cardCategory === "podcast" ? "none" : "#89C0FD19",
            borderRadius: "8px",
          }}
          className="image-upload"
        >
          <div className="row">
            {cardCategory === "podcast" ? (
              <div className="col-lg-12 col-3  mobile-only">&nbsp;</div>
            ) : (
              <>
                <div
                  className="col-lg-0 col-3 mobile-only"
                  style={{
                    textAlign: "center",
                    padding: "40px 0 0 30px ",
                    cursor: "pointer",
                  }}
                >
                  <img
                    alt={t("myo_random_artwork", "Random Art")}
                    style={{ cursor: "pointer", maxWidth: "40px" }}
                    onClick={() => {
                      handleRandomCoverImage();
                    }}
                    src="/img/random-cover.svg"
                  />
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleRandomCoverImage();
                    }}
                  >
                    <Trans i18nKey="myo_random_artwork">Random Art</Trans>
                  </div>
                </div>
              </>
            )}
            <div className="col-lg-12 col-6">
              {isUploading ? (
                <div
                  style={{
                    backgroundColor: "#322",
                    borderRadius: "5px",
                    padding: "8px",
                    margin: "2px",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  {!error ? (
                    <CircularProgress size="32px" color="primary" />
                  ) : (
                    <ErrorIcon fontSize="large" color="primary" />
                  )}
                </div>
              ) : (
                <img
                  alt={props.title}
                  className="card-cover-editable"
                  style={{}}
                  src={
                    coverImage ? coverImage : missingCoverImage(cardCategory)
                  }
                />
              )}
            </div>
            {cardCategory === "podcast" ? (
              <div className="col-lg-12 col-3  mobile-only">&nbsp;</div>
            ) : (
              <>
                <div
                  className="col-lg-6 col-0 desktop-only"
                  style={{ textAlign: "center", padding: "40px" }}
                >
                  <span style={{ cursor: "pointer" }}>
                    <img
                      alt={t("myo_random_artwork", "Random Art")}
                      style={{ cursor: "pointer", maxWidth: "40px" }}
                      onClick={() => {
                        handleRandomCoverImage();
                      }}
                      src="/img/random-cover.svg"
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleRandomCoverImage();
                      }}
                    >
                      <Trans i18nKey="myo_random_artwork">Random Art</Trans>
                    </div>
                  </span>
                </div>
                <div
                  className="col-lg-6 col-3"
                  style={{ textAlign: "center", padding: "32px 30px 0 0px" }}
                >
                  <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                    <img
                      alt={t("myo_upload_art", "Upload Art")}
                      src="/img/upload.svg"
                    />
                    <div style={{ fontWeight: "normal" }}>
                      <Trans i18nKey="myo_upload_art">Upload Art</Trans>
                    </div>
                    <input
                      id="file-input"
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        handleCoverImageFileSelect(event);
                        event.target.value = null; //reset so that onChange fires when same file is selected again
                      }}
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CoverImageDetail.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  cardCategory: PropTypes.string,
  onChange: PropTypes.func,
};

export default withTranslation()(CoverImageDetail);

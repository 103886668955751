import { useState } from "react";
import PropTypes from "prop-types";
import React from "react";

const LNG_EN = "en";
const LNG_FR = "fr";

export const LangSelectorPropTypes = {
  i18n: PropTypes.object,
};

export default function LangSelector({ i18n }) {
  //registered here since strings used with i18n.getFixed don't get picked up by the i18n-scanner
  i18n.t("common_language_short_name", "en");
  i18n.t("common_language_name", "English");

  const linkToCurrentPageWithLanguageParam = (lng) => {
    const LNG_PARAM = "lng";
    const url = new URL(window.location.href);
    url.searchParams.set(LNG_PARAM, lng);
    return url.href;
  };

  //selected language is always first in the list, hardcoded for now since we only have 2 languages
  let languages = [
    { lang: LNG_EN, selected: true },
    { lang: LNG_FR, selected: false },
  ];
  if (i18n.language === "fr") {
    languages = [
      { lang: LNG_FR, selected: true },
      { lang: LNG_EN, selected: false },
    ];
  }
  const [isOpen, setOpen] = useState(false);

  const onClick = async (e) => {
    const link = e.target.closest("A");
    const newLang = link.dataset.lang;
    const newLangIsSameAsCurrent = link && newLang === i18n.language;

    if (isOpen && !newLangIsSameAsCurrent) return; //BAU follow the link to change language

    //do nothing, just open or close the language selector menu
    e.preventDefault();
    e.stopPropagation();

    setOpen(!isOpen);
  };

  return (
    <div className={"langSelector"}>
      <ul
        className="langSelectorList"
        aria-label={i18n.t("common_language_selector", "Language selector")}
        onClick={onClick}
      >
        {languages.map(({ lang, selected }, i) => (
          <li
            key={lang}
            className="langSelectorItem"
            style={!isOpen && i > 0 ? { display: "none" } : {}}
          >
            <a
              href={linkToCurrentPageWithLanguageParam(lang)}
              className={`langSelectorLink${
                i === 0 ? "Selected" : ""
              } langSelector`}
              data-lang={lang}
              aria-current={selected || null}
              // More info: https://tink.uk/using-the-aria-current-attribute/
            >
              <span className={"langSelectorText p"} aria-hidden="true">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {i18n.getFixedT(
                  lang,
                  null,
                  null,
                )("common_language_short_name", i18n.language.toUpperCase())}
              </span>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <span className="sr-only">
                {i18n.getFixedT(
                  lang,
                  null,
                  null,
                )("common_language_name", i18n.language.toUpperCase())}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

LangSelector.propTypes = LangSelectorPropTypes;

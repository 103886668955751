import cryptoJs from 'crypto-js';
import {
    ChunkedFileReader,
    CHUNKED_FILE_READER_EVENTS
} from './ChunkedFileReader';

const base64urlHash = (hash) => {
    return hash.replace(/[+/=]/g, (m0) => {
        switch (m0) {
            case '+':
                return '-';
            case '/':
                return '_';
            case '=':
                return '';
            default:
                throw new Error(`unexpected base 64 char ${m0}`);
        }
    });
};

export function calculateFileHash(file) {
    if (!file || file.size <= 0) {
        // eslint-disable-next-line i18next/no-literal-string
        throw new Error('Cannot process empty file');
    }

    let SHA256 = cryptoJs.algo.SHA256.create();

    return new Promise((resolve) => {
        const reader = new ChunkedFileReader({ maxChunkSize: 4096 * 1024 });
        reader.subscribe(CHUNKED_FILE_READER_EVENTS.PROGRESS, function (evt) {
            console.log(
                `Progress ${evt.done} / ${evt.nchunks} chunks (${(
                    evt.done_ratio * 100
                ).toFixed(2)}%)`
            );
        });
        reader.subscribe(CHUNKED_FILE_READER_EVENTS.CHUNK, function (e) {
            const data = e.chunk;
            const wordBuffer = cryptoJs.lib.WordArray.create(data);
            SHA256.update(wordBuffer);
        });
        reader.subscribe(CHUNKED_FILE_READER_EVENTS.END, function () {
            const encrypted = SHA256.finalize();
            const hash = base64urlHash(
                cryptoJs.enc.Base64.stringify(encrypted)
            );
            console.log(
                `Hash of file ${file.name}, ${file.type} (${file.size}B) is ${hash}`
            );
            resolve(hash);
        });
        reader.readChunks(file);
    });
}

import React from "react";
import Page from "./Page";
import Typography from "@material-ui/core/Typography/Typography";

export default () => {
  return (
    <main style={{ padding: "32px" }}>
      <Page>
        <div style={{ textAlign: "center" }}>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Typography variant="subtitle1" style={{ padding: "4rem" }}>
            All remaining birthday shout-out slots have been filled.
          </Typography>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <p>
            Please read{" "}
            <a
              href="https://uk.yotoplay.com/blogs/yoto-journal/changes-to-birthday-shoutouts-on-yoto-daily"
              rel="noopener noreferrer"
              target="_blank"
            >
              Changes to Birthday Shoutouts on Yoto Daily
            </a>{" "}
            for more info.
          </p>
        </div>
      </Page>
    </main>
  );
};

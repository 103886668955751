// Custom Yoto variants of the MUI Button.

import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";

// CSS copied from yoto-web-jamstack .primary button:
export const PrimaryButton = styled(Button)(() => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  height: 56,
  width: "100%",
  // minWidth: 200,
  padding: "0 16px 4px",
  color: "#fff",
  font: "900 20px/1.4 'castledown-regular', sans-serif",
  margin: 0,
  backgroundColor: "#F85E41",
  border: "0 solid transparent",
  borderRadius: 12,
  boxShadow: "inset 0 -4px 0 0 rgba(0, 0, 0, 0.25)",
  cursor: "pointer",
  transition:
    "background-color 0.2s ease-in-out, height 0.05s ease-in-out, transform 0.05s ease-in-out",

  // Prevent less dextrous users from accidentally selecting button text:
  userSelect: "none",

  textTransform: "none",

  // Hide unwanted MUI animation element:
  ".MuiTouchRipple-root": {
    display: "none",
  },

  //   @include from($bp-sm) {
  //     height: 64px;
  //     padding: 0 32px 4px;
  //     font-size: 20px;
  //     line-height: 1.4;
  //     border-radius: 12px;
  //   }

  "&:hover": {
    transitionDuration: "0.05s",
    backgroundColor: "#d7543b",
  },

  "&:focusVisible": {
    boxShadow: `
            0 0 0 4px #fff,
            0 0 0 6px #1374e0,
            inset 0 -4px 0 0 rgba(0, 0, 0, 0.25)`,
    outline: "none",
  },

  "&:focusVisible:active": {
    boxShadow: `
            0 0 0 4px #fff,
            0 0 0 6px #1374e0,
            inset 0 4px 0 0 rgba(0, 0, 0, 0.25)`,
  },

  "&:active": {
    backgroundColor: "#ba4731",
    transform: "scaleY(0.929) translateY(4px)",
    boxShadow: "inset 0 0 0 0 rgba(0, 0, 0, 0.25)",

    // @include from($bp-sm) {
    //   transform: scaleY(0.9) translateY(4px);
    // }

    // @include from($bp-md) {
    //   transform: scaleY(0.938) translateY(4px);
    // }
  },

  '&[aria-disabled="true"], &:disabled, &.Mui-disabled': {
    backgroundColor: "#FBF9F9",
    boxShadow: "none",
    color: "#666",
    pointerEvents: "none",
  },

  "&.small": {
    height: 40,
    borderRadius: 8,
    padding: "0 24px",

    "&:active": {
      height: 40,
    },
  },
}));

// CSS copied from yoto-web-jamstack .secondary button:
export const SecondaryButton = styled(PrimaryButton)(() => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  color: "#1374E0",
  border: "solid 2px currentColor",

  "&:hover": {
    backgroundColor: "transparent",
    color: "#f85e41",
    border: "solid 2px #f85e41",
  },

  "&:active": {
    height: 56,
    transform: "none",
    color: "#003c1f",
    border: "solid 2px #003c1f",

    //   @include from($bp-sm) {
    //     height: 40px,
    //   }

    //   @include from($bp-md) {
    //     height: 64px,
    //   }
  },

  "&:focusVisible": {
    boxShadow: "0 0 0 4px #fff, 0 0 0 6px currentColor",
    outline: "none",
  },

  '&[aria-disabled="true"], &:disabled, &.Mui-disabled': {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    color: "#666",
    border: "solid 2px #f5f0f0",
  },
}));

export const DangerButton = styled(PrimaryButton)(() => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  color: "#D21919",
  border: "solid 2px currentColor",

  "&:hover": {
    backgroundColor: "transparent",
    color: "#181818",
    border: "solid 2px #181818",
  },

  "&:active": {
    height: 56,
    transform: "none",
    color: "#003c1f",
    border: "solid 2px #003c1f",

    //   @include from($bp-sm) {
    //     height: 40px,
    //   }

    //   @include from($bp-md) {
    //     height: 64px,
    //   }
  },

  "&:focusVisible": {
    boxShadow: "0 0 0 4px #fff, 0 0 0 6px currentColor",
    outline: "none",
  },

  '&[aria-disabled="true"], &:disabled, &.Mui-disabled': {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    color: "#666",
    border: "solid 2px #f5f0f0",
  },
}));

import React, { Component } from "react";
import { isLoggedIn } from "../utils/AuthService";
import "../App.css";
import { browserHistory } from "react-router";

const URL_MYO_LOGOUT_REDIRECT = "/create";

class AutoLogout extends Component {
  componentDidMount() {
    const isLoggedInCheckINterval = setInterval(() => {
      //console.log('checking login status');
      if (!isLoggedIn()) {
        console.log("Logging out automatically.");
        browserHistory.push(URL_MYO_LOGOUT_REDIRECT);
      }
    }, 30 * 1000);
    // store isLoggedInCheckINterval in the state so it can be accessed later:
    this.setState({ intervalId: isLoggedInCheckINterval });
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    if (this.state && this.state.intervalId)
      clearInterval(this.state.intervalId);
  }

  render() {
    return <></>;
  }
}

export default AutoLogout;

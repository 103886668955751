import React, { Component } from "react";
import DeviceConfigV1 from "./DeviceConfigV1";
import DeviceConfigV2 from "./DeviceConfigV2";
import PropTypes from "prop-types";
const api = require("../utils/yoto-api");

class DeviceConfig extends Component {
  constructor(props) {
    super(props);
    //console.log('device props');
    //console.log(JSON.stringify(props));
    this.props = props;
  }

  render() {
    return api.getDeviceVersion(this.props.params.deviceId) === "v1" ? (
      <DeviceConfigV1
        params={this.props.params}
        location={this.props.location}
      />
    ) : (
      <DeviceConfigV2
        params={this.props.params}
        location={this.props.location}
      />
    );
  }
}

DeviceConfig.propTypes = {
  location: PropTypes.object,
  params: PropTypes.object,
};

export default DeviceConfig;

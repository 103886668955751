import React, { Component } from "react";
import PropTypes from "prop-types";

class CardCover extends Component {
  constructor(props) {
    super(props);
    //console.log(JSON.stringify(props));
    this.state = {};
    this.state.coverImageUrl = props.coverImageUrl;
    this.state.cardTitle = props.cardTitle;
    this.state.cardCategory = props.cardCategory;
  }

  missingCoverImage(cardCategory) {
    const MISSINC_COVER_DEFAULT = "/img/MYO.png";
    const MISSINC_COVER_PODCAST = "/img/missing-cover-podcast.png";
    switch (cardCategory) {
      case "podcast":
        return MISSINC_COVER_PODCAST;
      default:
        return MISSINC_COVER_DEFAULT;
    }
  }

  placeholderImage(cardCategory) {
    const PLACEHOLDER_DEFAULT = "/img/card-cover-list-placeholder.png";
    const PLACEHOLDER_PODCAST = "/img/card-cover-list-placeholder-podcast.png";
    switch (cardCategory) {
      case "podcast":
        return PLACEHOLDER_PODCAST;
      default:
        return PLACEHOLDER_DEFAULT;
    }
  }

  render() {
    let { cardCategory, cardTitle } = this.state;
    if (!cardTitle) cardTitle = "";
    return (
      <span style={{ display: "block", fontSize: "1.4em" }}>
        <div className="card-cover-listing-container">
          <img
            alt={cardTitle}
            className={
              this.state.cardCategory === "podcast"
                ? "card-cover-listing-podcast"
                : "card-cover-listing"
            }
            style={{
              backgroundImage: `url(${
                this.state.coverImageUrl || this.missingCoverImage(cardCategory)
              })`,
            }}
            src={this.placeholderImage(cardCategory)}
          />
        </div>
        <div className="card-title-text">
          {cardTitle.length > 42 ? `${cardTitle.slice(0, 38)}...` : cardTitle}
        </div>
      </span>
    );
  }
}

CardCover.propTypes = {
  coverImageUrl: PropTypes.string,
  cardTitle: PropTypes.string,
  cardCategory: PropTypes.string,
};

export default CardCover;

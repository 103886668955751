import { Component } from "react";
import PropTypes from "prop-types";
import { setIdToken, setAccessToken } from "../utils/AuthService";
import queryString from "qs";

const sanitizeRelativeUrl = function (unsafeUrl) {
  if (!unsafeUrl) return "/";
  const url = new URL(unsafeUrl, window.location.origin);
  if (url.origin === window.location.origin) return unsafeUrl;
  return "/";
};

class Callback extends Component {
  componentDidMount() {
    const qs = queryString.parse(this.props.location.search.split("?")[1]);
    setAccessToken();
    setIdToken();
    window.location.href = sanitizeRelativeUrl(qs.returnUrl);
  }

  render() {
    return null;
  }
}

Callback.propTypes = {
  location: PropTypes.object,
};

export default Callback;
